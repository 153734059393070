const selectItemNormalizer = data => {
  if (!data) return [];

  if (!Array.isArray(data)) {
    data = [data];
  }

  return data.map(item => ({
    label: item.name || item.label,
    value: item.id || item.value,
  }));
};

export default selectItemNormalizer;
