import { useEffect, useState } from 'react';
import { Form } from 'antd';
import './styles.css';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import NewPassView from './new-password-view';
import ApiAuth from '../../api/auth';
import useNotification from '../../components/notification/notification';

function NewPass() {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const [inProgress] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const [searchParams] = useSearchParams();
  const { openNotification } = useNotification();
  const navigation = useNavigate();
  const [errorFields, setErrorFields] = useState();
  const [tokenError, setTokenError] = useState();
  const [checkToken, setCheckToken] = useState(true);

  const onChange = () => {
    form
      .validateFields()
      .then(() => {
        setErrorFields([]);
      })
      .catch(r => {
        const errorsList = r.errorFields?.reduce((acc, field) => [...acc, ...field.errors.map(e => e)], []);
        const list = errorsList ? [...errorsList] : [];
        setErrorFields([...list]);
      });
  };

  const newPassword = () => {
    const { password } = form.getFieldsValue();
    const token = searchParams.get('token');

    ApiAuth.newPassword({ password, token })
      .then(() => {
        openNotification({
          type: 'success',
          message: <p>{t('login.password_updated')}</p>,
        });
        localStorage.removeItem('user_token');
        navigation('/login');
      })
      .catch(e => {
        if (e.response?.data?.error?.message) {
          setErrorMsg(e.response.data.error.message);
        }
      });
  };

  useEffect(() => {
    const token = searchParams.get('token');

    if (token) {
      setCheckToken(true);

      ApiAuth.checkToken(token)
        .catch(e => {
          const { data } = e.response;
          setTokenError(data.message || 'already_exist');
        })
        .finally(() => {
          setCheckToken(false);
        });
    } else {
      setCheckToken(false);
    }
  }, []);

  return (
    <NewPassView
      form={form}
      t={t}
      inProgress={inProgress}
      newPassword={newPassword}
      errorMsg={errorMsg}
      onChange={onChange}
      errorFields={errorFields}
      checkToken={checkToken}
      tokenError={tokenError}
    />
  );
}

export default NewPass;
