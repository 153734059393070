import { CloseOutlined } from '@ant-design/icons';
import { Avatar, message, Upload } from 'antd';
import { useEffect, useState } from 'react';
import './styles.css';

const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
};
const beforeUpload = file => {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    message.error('You can only upload JPG/PNG file!');
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error('Image must smaller than 2MB!');
  }
  return isJpgOrPng && isLt2M;
};

const UploadAvatar = ({ onChange, value }) => {
  const [imageUrl, setImageUrl] = useState(value);
  const handleChange = info => {
    getBase64(info.file.originFileObj, url => {
      setImageUrl(url);
    });
  };

  const uploadButton = <div className="avatar-upload-btn">Upload</div>;

  const removeImage = e => {
    e.stopPropagation();
    setImageUrl();
  };

  useEffect(() => {
    if (onChange) {
      onChange(imageUrl);
    }
  }, [imageUrl]);

  if (imageUrl) {
    return (
      <div className="avatar-container">
        <Avatar src={imageUrl} alt="avatar" size={128} />
        <CloseOutlined style={{ cursor: 'pointer' }} onClick={removeImage} />
      </div>
    );
  }

  return (
    <Upload
      name="avatar"
      className="avatar-placeholder"
      showUploadList={false}
      beforeUpload={beforeUpload}
      onChange={handleChange}
    >
      {uploadButton}
    </Upload>
  );
};
export default UploadAvatar;
