import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';
import { selectIsAdmin } from '../../store/slices/user';

const AdminRoutes = () => {
  const admin = useSelector(selectIsAdmin);

  return admin ? <Outlet /> : <Navigate to="/" />;
};

export default AdminRoutes;
