import Header from '../../../components/header/header';

const UserManagementHeader = ({ openInviteUserModal, title, t, author, search, onChange }) => (
  <Header
    title={title}
    search={{
      value: search,
      onChange,
      placeholder: t('assets.btn.search'),
    }}
    create={{
      label: t('user_management.btn.invite'),
      onClick: () => openInviteUserModal({ author }),
    }}
  />
);

export default UserManagementHeader;
