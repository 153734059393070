import { Form, Tag } from 'antd';

const AssetInfoFormCompetitive = ({ t, form }) => {
  const intelligence = items =>
    items?.map(item => (
      <Tag key={item.id} style={{ fontSize: 14, border: 'none', background: 'rgba(108, 108, 137, 0.08)' }}>
        {item.label || item.name}
      </Tag>
    ));

  return (
    <>
      <h3>{t('assets.info.form.competitive')}</h3>
      <Form.Item>
        <div>
          <p>{t('assets.info.form.products')}</p>
          <div style={{ maxWidth: 400, display: 'block' }}>{intelligence(form.intelligences)}</div>
          {!form.intelligences?.length && <Tag />}
        </div>
      </Form.Item>
      <Form.Item>
        <div>
          <p>{t('assets.info.form.tags')}</p>
          <div style={{ maxWidth: 400, display: 'block' }}>{intelligence(form.intelligenceTags)}</div>
          {!form.intelligenceTags?.length && <Tag />}
        </div>
      </Form.Item>
    </>
  );
};

export default AssetInfoFormCompetitive;
