import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useDispatch } from 'react-redux';
import ApiDashboard from '../../../api/dashboard';
import getDate from '../../../common/helpers/calendar/get-date';
import ResizableTable from '../../../components/resizable-table/resizable-table';
import { setCurrentAsset } from '../../../store/slices/assets';
import AssetCard from '../../assets/components/asset-card';

const LastAdded = ({ documents, isLoading, categories }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const setAsset = asset => {
    dispatch(setCurrentAsset(asset));
    ApiDashboard.postOpenedAsset(asset.id);
  };

  const getCategoryName = categoryId => categories.filter(c => c.id === categoryId)[0]?.name;

  const columns = useMemo(
    () => [
      {
        title: t('dashboard.table.last_added'),
        dataIndex: 'name',
        render: (_, document) => <AssetCard data={document} selectAsset={setAsset} />,
        key: 'name',
        width: '50%',
      },
      {
        title: t('dashboard.table.created'),
        dataIndex: 'created_at',
        key: 'created_at',
        render: (_, document) => <span>{getDate(document.createdAt)}</span>,
      },
      {
        title: t('dashboard.table.category'),
        dataIndex: 'category',
        key: 'category',
        render: (_, document) => <span>{getCategoryName(document.categoryId) || 'N/A'}</span>,
      },
    ],
    [categories]
  );

  return (
    <div className="dashboard__last-added" data-testid="last-added">
      <ResizableTable
        rowClassName="clickable"
        columns={columns}
        dataSource={documents}
        pagination={false}
        rowKey={doc => doc.id}
        loading={isLoading}
        onRow={data => ({
          onClick: () => setAsset(data),
        })}
        showSorterTooltip={false}
      />
    </div>
  );
};

export default LastAdded;
