import { HolderOutlined } from '@ant-design/icons';
import { Input } from 'antd';
import { useRef, useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import AdditionalBtns from './additional-btns';

const DraggableItem = ({ id, text, className, style, moveItem, index, canDrag, updateItemName, remove }) => {
  const [isMarkVisible, setIsMarkVisible] = useState(false);
  const [isEditMode, setEditMode] = useState(false);
  const [name, setName] = useState(text);

  const ref = useRef(null);

  const [{ handlerId }, drop] = useDrop({
    accept: 'item',
    collect: monitor => ({ handlerId: monitor.getHandlerId() }),
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) {
        return;
      }

      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      moveItem(dragIndex, hoverIndex);

      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: 'item',
    item: () => ({
      id,
      index,
    }),
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0 : 1;

  drag(drop(ref));

  const setMarksVisibility = () => {
    setIsMarkVisible(true);
  };

  const setMarkHide = () => {
    setIsMarkVisible(false);
  };

  const toogleToEditMode = () => {
    setEditMode(!isEditMode);
  };

  const updateName = e => {
    setName(e.target.value);
  };

  const save = () => {
    updateItemName(id, name);
    setEditMode(false);
  };

  const cancel = () => {
    setName(text);
    setEditMode(false);
  };

  return (
    <div
      ref={canDrag && !isEditMode ? ref : null}
      onMouseEnter={setMarksVisibility}
      onMouseLeave={setMarkHide}
      className={className}
      data-handler-id={handlerId}
      style={{ opacity }}
    >
      <div className="draggable-item__right">
        <HolderOutlined style={{ marginRight: 10, opacity: isMarkVisible && canDrag ? 1 : 0, transition: '0.2s' }} />
        {isEditMode ? (
          <Input
            size="small"
            autoFocus={true}
            onChange={updateName}
            value={name}
            bordered={false}
            style={{ ...style, zIndex: 1 }}
          />
        ) : (
          <div style={style}>{name}</div>
        )}
      </div>
      <AdditionalBtns
        isEditMode={isEditMode}
        id={id}
        name={name}
        style={{ opacity: isMarkVisible && canDrag ? 1 : 0, transition: '0.2s', zIndex: 1 }}
        toogleEdit={toogleToEditMode}
        save={save}
        cancel={cancel}
        remove={remove}
      />
    </div>
  );
};

export default DraggableItem;
