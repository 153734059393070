import { Modal } from 'antd';

const ModalWrapper = ({
  isOpen,
  title,
  width,
  children,
  onOk,
  onCancel,
  okText,
  cancelText,
  okButtonProps,
  cancelButtonProps,
  confirmLoading,
}) => (
  <Modal
    title={title}
    centered
    open={isOpen}
    onOk={onOk}
    onCancel={onCancel}
    width={width}
    okText={okText}
    cancelText={cancelText}
    okButtonProps={okButtonProps}
    cancelButtonProps={cancelButtonProps}
    confirmLoading={confirmLoading}
  >
    {children}
  </Modal>
);
export default ModalWrapper;
