import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ApiAdministration from '../../../api/administration';
import ApiAssets from '../../../api/assets';
import useNotification from '../../../components/notification/notification';
import AdministrationManagementDnDList from '../components/dnd-list/dnd-list';

const Products = () => {
  const { t } = useTranslation();
  const { openNotification } = useNotification();
  const [products, setProducts] = useState([]);

  const getProducts = () =>
    ApiAssets.getProducts().then(r => {
      setProducts(r.data.products);
      return r.data.products;
    });

  const create = newProduct =>
    ApiAdministration.createProduct({ name: newProduct.name }).then(() => {
      openNotification({
        type: 'success',
        message: <p>{`Product "${newProduct.name}" was created`}</p>,
      });
    });

  const update = items => {
    const data = items;
    const productOrder = items.map(p => p.id);

    return ApiAdministration.updateProducts({ data, productOrder })
      .then(() => {
        openNotification({
          type: 'success',
          message: <p>{t('administration.products.updated')}</p>,
        });
      })
      .catch(() => {
        openNotification({
          type: 'error',
          message: <p>{t('administration.products.updated_error')}</p>,
        });
      });
  };

  const remove = id =>
    ApiAdministration.deleteProduct(id)
      .then(() => {
        openNotification({
          type: 'success',
          message: <p>{`Product "${products.filter(p => p.id === id)[0].name}" was deleted`}</p>,
        });

        setProducts(products.filter(p => p.id !== id));
      })
      .catch(() => {
        openNotification({
          type: 'error',
          message: <p>{`Product "${products.filter(c => c.id === id)[0].name}" delete error`}</p>,
        });
      });

  return (
    <AdministrationManagementDnDList
      fetchData={getProducts}
      save={update}
      create={create}
      remove={remove}
      translationPath="administration.products"
    />
  );
};

export default Products;
