import axios from 'axios';

const getNotifications = () => axios.get('api/v1/local-notifications');
const setReadNotifications = ids => axios.put('api/v1/local-notifications', { ids });

const ApiNotifications = {
  getNotifications,
  setReadNotifications,
};

export default ApiNotifications;
