import { Form, Tag } from 'antd';
import getDate from '../../../../../../common/helpers/calendar/get-date';

const getBool = value => {
  if (value === null) {
    return 'N/A';
  }
  if (value) {
    return 'Yes';
  }
  return 'No';
};
const AssetInfoFormInformation = ({ t, form }) => (
  <>
    <h3>{t('assets.info.form.info')}</h3>
    <Form.Item name="rpcApproved">
      <div>
        <p>{t('assets.info.form.prc')}</p>
        <span>{getBool(form.prcApproved)}</span>
      </div>
    </Form.Item>
    <Form.Item name="mrcApproved">
      <div>
        <p>{t('assets.info.form.mrc')}</p>
        <span>{getBool(form.mrcApproved)}</span>
      </div>
    </Form.Item>
    <Form.Item name="expirationDate">
      <div>
        <p>{t('assets.info.form.expiration')}</p>
        <span>{getDate(form.expirationDate)}</span>
      </div>
    </Form.Item>
    <Form.Item name="fileOrigin">
      <div>
        <p>{t('assets.info.form.origin')}</p>
        <span>{form.fileOrigin?.name || 'N/A'}</span>
      </div>
    </Form.Item>
    <Form.Item name="products">
      <div>
        <p>{t('assets.info.form.product')}</p>
        <div style={{ maxWidth: 400, display: 'block' }}>
          {(form.products || []).map(i => (
            <Tag key={i.id} style={{ fontSize: 14, border: 'none', background: 'rgba(108, 108, 137, 0.08)' }}>
              {i.label || i.name}
            </Tag>
          ))}
        </div>
        {!form.products?.length && <Tag />}
      </div>
    </Form.Item>
    <Form.Item name="heorResearch">
      <div>
        <p>{t('assets.info.form.heor')}</p>
        <span>{form.heorResearch ? 'Yes' : 'No'}</span>
      </div>
    </Form.Item>
    <Form.Item name="indications">
      <div>
        <p>{t('assets.info.form.indications')}</p>
        <div style={{ maxWidth: 400, display: 'block' }}>
          {(form.indications || []).map(i => (
            <Tag key={i.id} style={{ fontSize: 14, border: 'none', background: 'rgba(108, 108, 137, 0.08)' }}>
              {i.label || i.name}
            </Tag>
          ))}
        </div>
        {!form.indications?.length && <Tag />}
      </div>
    </Form.Item>
    <Form.Item name="tags">
      <div>
        <p>{t('assets.info.form.tags')}</p>
        <div style={{ maxWidth: 400, display: 'block' }}>
          {(form.tags || []).map(tag => (
            <Tag key={tag.id} style={{ fontSize: 14, border: 'none', background: 'rgba(108, 108, 137, 0.08)' }}>
              {tag.label || tag.name}
            </Tag>
          ))}
        </div>
        {!form.tags?.length && <Tag />}
      </div>
    </Form.Item>
  </>
);

export default AssetInfoFormInformation;
