import { ReactComponent as ChechboxIcon } from '../../assets/images/custom-icons/checkbox.svg';
import './styles.css';

const ValidationChecklist = ({ validationRules, errorFields = [], touched, varificationValue }) => {
  const listItems = validationRules.map(rule => {
    const { text, invalidColor, validColorCheckbox, validColorText } = rule;

    const isValid = varificationValue && varificationValue.length && touched && !errorFields.includes(text);

    const checkboxColor = isValid ? validColorCheckbox : invalidColor;
    const textColor = isValid ? validColorText : invalidColor;

    return (
      <div key={text} className="checklist-item">
        <ChechboxIcon style={{ color: checkboxColor }} />
        <span style={{ color: textColor }}>{text}</span>
      </div>
    );
  });

  return listItems;
};

export default ValidationChecklist;
