import axios from 'axios';
import downloadBlob from '../common/helpers/download-blob';
import toQueryString from '../common/helpers/to-query-string';

const getTrails = query => axios.get(toQueryString('api/v1/trails?', query));

const exportTrails = () =>
  axios.get('/api/v1/trails?content=1').then(resp => {
    const { content, filename } = resp.data;
    downloadBlob(content, filename);
  });

const ApiTrails = {
  getTrails,
  exportTrails,
};

export default ApiTrails;
