import axios from 'axios';

const getSettings = () => axios.get('api/v1/settings');

const updateSettings = params => axios.put('api/v1/settings', params);

const createCategory = params => axios.post('api/v1/categories', params);

const updateCategories = params => axios.put('api/v1/categories', params);

const deleteCategory = id => axios.delete(`/api/v1/categories/${id}`);

const createProduct = params => axios.post('/api/v1/products', params);

const updateProducts = params => axios.put('/api/v1/products', params);

const deleteProduct = id => axios.delete(`/api/v1/products/${id}`);

const createFileOrigin = params => axios.post('/api/v1/fileorigins', params);

const updateFileOrigins = params => axios.put('/api/v1/fileorigins', params);

const deleteFileOrigin = id => axios.delete(`/api/v1/fileorigins/${id}`);

const ApiAdministration = {
  getSettings,
  updateSettings,
  updateCategories,
  deleteCategory,
  createCategory,
  createProduct,
  updateProducts,
  deleteProduct,
  createFileOrigin,
  updateFileOrigins,
  deleteFileOrigin,
};

export default ApiAdministration;
