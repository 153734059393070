import excelIcon from '../assets/images/custom-icons/documents/excel.svg';
import wordIcon from '../assets/images/custom-icons/documents/word.svg';
import pdfIcon from '../assets/images/custom-icons/documents/pdf.svg';
import zipIcon from '../assets/images/custom-icons/documents/zip.svg';
import powerPointIcon from '../assets/images/custom-icons/documents/power_point.svg';

const fileIcons = fileTypeId => {
  switch (fileTypeId) {
    case 1:
      return wordIcon;
    case 2:
      return excelIcon;
    case 3:
      return pdfIcon;
    case 4:
      return powerPointIcon;
    case 5:
      return zipIcon;

    default:
      return powerPointIcon;
  }
};

export default fileIcons;
