import { Table } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch, useSelector } from 'react-redux';
import InviteUser from '../../components/menu/invite-user/invite-user';
import {
  fetchUsers,
  selectHasMoreUsers,
  selectUsers,
  selectUsersLoading,
  setIsOpenProfile,
  setProfileUser,
} from '../../store/slices/user_management';
import UserCard from './components/user-card';
import UserManagementHeader from './components/user-management-header';
import './styles.css';
import getDateByDiff from '../../common/helpers/calendar/get-date-by-diff';

const UserManagament = () => {
  const users = useSelector(selectUsers);
  const hasMore = useSelector(selectHasMoreUsers);
  const dispatch = useDispatch();
  const [search, setSearch] = useState();
  const [isOpenUserModal, setIsOpenUserModal] = useState(false);
  const { hash } = useLocation();
  const isLoading = useSelector(selectUsersLoading);

  const { t } = useTranslation();

  const fetchUsersList = params => {
    let status;
    switch (hash) {
      case '#disabled':
        status = 'inactive';
        break;
      case '#pending':
        status = ['invited', 'inviteError'];
        break;
      default:
        break;
    }
    dispatch(fetchUsers({ status, ...params }));
  };

  useEffect(() => {
    fetchUsersList();
  }, []);

  const updateSearchValue = e => {
    const { value } = e.target;
    if (value && value.trim().length) {
      setSearch(value);
    } else {
      setSearch(null);
    }
  };

  const openInviteUserModal = () => {
    setIsOpenUserModal(true);
  };

  const closeInviteUserModal = () => {
    setIsOpenUserModal(false);
  };

  const openUserProfile = user => {
    dispatch(setProfileUser(user));
    dispatch(setIsOpenProfile(true));
  };

  const userRoleRender = role => {
    const roles = {
      admin: 'Administrator',
      user: 'User',
    };

    return <span className={`user-management__user-role--${role}`}>{roles[role]}</span>;
  };

  const userStatusRender = status => {
    const statuses = {
      invited: 'Invited',
      inviteError: 'Invited error',
      confirmed: 'Active',
      blocked: 'Blocked',
      inactive: 'Inactive',
    };

    return <span className={`user-management__user-status--${status}`}>{statuses[status] || 'N/A'}</span>;
  };

  useEffect(() => {
    fetchUsersList({ search });
  }, [search, hash]);

  const headerTitle = () => {
    switch (hash) {
      case '#disabled':
        return t('user_management.title_disabled');
      case '#pending':
        return t('user_management.title_pending');
      default:
        return t('user_management.title');
    }
  };

  const columns = [
    {
      title: t('user_management.table.name'),
      dataIndex: 'name',
      render: (_, user) => <UserCard user={user} />,
      key: 'name',
      width: '25%',
    },
    {
      title: t('user_management.table.email'),
      dataIndex: 'email',
      key: 'email',
      render: (_, user) => <span>{user.email || 'N/A'}</span>,
      width: '30%',
    },
    {
      title: t('user_management.table.role'),
      dataIndex: 'role',
      key: 'role',
      render: (_, user) => userRoleRender(user.role),
    },
    {
      title: t('user_management.table.last_active'),
      dataIndex: 'last_active',
      key: 'last_active',
      render: (_, user) => <span>{getDateByDiff(user.lastActivity)}</span>,
    },
    {
      title: t('user_management.table.status'),
      dataIndex: 'status',
      key: 'status',
      render: (_, user) => userStatusRender(user.status),
    },
  ];

  return (
    <div className="user-management">
      <UserManagementHeader
        t={t}
        title={headerTitle()}
        onChange={updateSearchValue}
        value={search}
        openInviteUserModal={openInviteUserModal}
      />
      <InfiniteScroll dataLength={users.length} next={fetchUsers} hasMore={hasMore} height={window.screen.height - 250}>
        <Table
          className="assets-list__table user-management__table"
          columns={columns}
          dataSource={users}
          pagination={false}
          rowKey={user => user.id}
          onRow={user => ({ onClick: () => openUserProfile(user) })}
          loading={isLoading}
          rowClassName="assets-list__table__row clickable"
          data-testid="users-table"
        />
      </InfiniteScroll>

      <InviteUser isOpen={isOpenUserModal} close={closeInviteUserModal} />
    </div>
  );
};

export default UserManagament;
