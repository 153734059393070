import { useState, useRef } from 'react';
import { Table } from 'antd';
import ResizableTableCell from './resizable-table-cell';
import debounce from '../../common/helpers/debounce';

const ResizableTable = ({
  columns,
  rowClassName,
  dataSource,
  pagination,
  rowKey,
  onRow,
  sort,
  loading,
  sortBy,
  sortOrder,
}) => {
  const [tableColumns, setTableColumns] = useState(columns);
  const [resizeCell, setResizeCellIndex] = useState({ cellIndex: -1 });
  const [scrollable, setScrollable] = useState(false);
  const cellRef = useRef([]);

  const handleResize = (index, width) => {
    debounce(
      setTableColumns(col => {
        const nextColumns = [...col];
        nextColumns[index] = {
          ...nextColumns[index],
          width: nextColumns[index].resizable && width,
        };
        return nextColumns;
      }),
      50
    );
  };

  const updateResizeCell = cellIndex => {
    if (!scrollable) {
      setScrollable(true);
    }
    setResizeCellIndex({ cellIndex });
  };

  const handleRef = (node, index) => {
    cellRef.current[index] = node;
  };

  const sortOrderValue = columnKey => {
    if (!sortBy || columnKey !== sortBy) {
      return null;
    }

    return sortOrder === 'ASC' ? 'ascend' : 'descend';
  };

  return (
    <div>
      <Table
        columns={tableColumns.map((column, index) => ({
          ...column,
          onHeaderCell: () => ({
            index,
            width: column.width,
          }),
          sortOrder: sortOrderValue(column.key),
        }))}
        dataSource={dataSource}
        components={{
          header: {
            cell: cellProps => (
              <ResizableTableCell
                cellProps={cellProps}
                handleResize={handleResize}
                resizeCell={resizeCell}
                updateResizeCell={updateResizeCell}
                cellRef={cellRef}
                handleRef={handleRef}
                resizable={columns[cellProps.index].resizable}
                sort={sort}
                columns={columns}
                sortBy={sortBy}
                sortOrder={sortOrder}
              />
            ),
          },
        }}
        rowClassName={rowClassName}
        pagination={pagination}
        rowKey={rowKey}
        onRow={onRow}
        sort={false}
        showSorterTooltip={false}
        scroll={{ x: scrollable && 'max-content' }}
        data-testid="resizable-table"
        loading={loading}
      />
    </div>
  );
};

export default ResizableTable;
