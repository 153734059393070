import moment from 'moment';

const getDate = date => {
  if (date) {
    return moment.utc(date).format('DD MMM YYYY');
  }

  return '-';
};

export default getDate;
