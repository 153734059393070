import axios from 'axios';

const update = (userId, params) => axios.put(`/api/v1/users/${userId}`, params);

const activate = (userId, params) => axios.post(`/api/v1/users/${userId}/activate`, params);

const deactivate = (userId, params = {}) => axios.post(`/api/v1/users/${userId}/deactivate`, params);

const ApiUser = {
  update,
  activate,
  deactivate,
};

export default ApiUser;
