import { Input, Modal } from 'antd';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useTranslation } from 'react-i18next';
import ListComponent from './list-component';
import Header from '../../../../components/header/header';
import useNotification from '../../../../components/notification/notification';

const AdministrationManagementDnDList = ({ fetchData, save, create, remove, translationPath }) => {
  const { t } = useTranslation();
  const { openNotification } = useNotification();
  const [listItems, setListItems] = useState([]);
  const [defaultValues, setDefaultValues] = useState();
  const [hasChanges, setChanged] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newItemName, setNewItemName] = useState();

  const fetch = () => {
    fetchData().then(items => {
      setListItems(items);
      setDefaultValues(items);
    });
  };

  const moveElement = (arr, fromIndex, toIndex) => {
    const item = arr[fromIndex];
    const { length } = arr;
    const diff = fromIndex - toIndex;

    if (diff > 0) {
      return [...arr.slice(0, toIndex), item, ...arr.slice(toIndex, fromIndex), ...arr.slice(fromIndex + 1, length)];
    }

    if (diff < 0) {
      return [
        ...arr.slice(0, fromIndex),
        ...arr.slice(fromIndex + 1, toIndex + 1),
        item,
        ...arr.slice(toIndex + 1, length),
      ];
    }

    return arr;
  };

  const moveItem = useCallback((dragIndex, hoverIndex) => {
    setChanged(true);
    setListItems(prevItems => {
      const newItems = [...prevItems];
      return moveElement(newItems, dragIndex, hoverIndex);
    });
  }, []);

  const updateItemName = (id, name) => {
    setListItems(listItems.map(c => (c.id !== id ? c : { ...c, name })));
    setChanged(true);
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const hideModal = () => {
    setIsModalOpen(false);
    setNewItemName();
  };

  const addNew = () => {
    const hasEqualNameInLists = listItems.filter(i => i.name === newItemName).length;

    if (hasEqualNameInLists) {
      openNotification({
        type: 'error',
        message: <p>This name already exists</p>,
      });

      return;
    }

    if (newItemName && newItemName.trim().length) {
      const newItem = {
        name: newItemName,
        domainId: listItems.filter(c => c.domain)[0]?.id,
        id: newItemName,
      };
      setListItems([...listItems.splice(0, 1), newItem, ...listItems.slice(1)]);

      create(newItem).then(() => {
        fetch();
      });

      hideModal();
    }
  };

  const cancel = () => {
    setListItems(defaultValues);
    setChanged(false);
  };

  const saveData = () => {
    save(listItems);
    setChanged(false);
  };

  const removeItem = id => {
    remove(id).then(() => {
      fetch();
    });
  };

  const updateNewCategoryName = e => {
    setNewItemName(e.target.value);
  };

  const list = useMemo(
    () => (
      <ListComponent listItems={listItems} moveItem={moveItem} updateItemName={updateItemName} remove={removeItem} />
    ),
    [listItems]
  );

  useEffect(() => {
    fetch();
  }, []);

  return (
    <div className="administration">
      <Header
        className="administration__header"
        title={t(`${translationPath}.title`)}
        create={{ label: t(`${translationPath}.create`), onClick: showModal }}
        apply={hasChanges && { label: 'Save', onClick: saveData }}
        cancel={hasChanges && { label: 'Cancel', onClick: cancel }}
      />
      <DndProvider backend={HTML5Backend}>{list}</DndProvider>
      <Modal title="Create new category" open={isModalOpen} onOk={addNew} onCancel={hideModal}>
        <Input value={newItemName} autoFocus={true} onChange={updateNewCategoryName} />
      </Modal>
    </div>
  );
};

export default AdministrationManagementDnDList;
