import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import NotificationSettings from './notifications/notifications-settings';
import './styles.css';
import Category from './list-management/category';
import Products from './list-management/products';
import FileOrigin from './list-management/file-origin';

const Administration = () => {
  const { hash } = useLocation();

  const content = useMemo(() => {
    switch (hash) {
      case '#category':
        return <Category />;

      case '#notifications':
        return <NotificationSettings />;

      case '#products':
        return <Products />;

      case '#file_origin':
        return <FileOrigin />;

      default:
        return <Category />;
    }
  }, [hash]);

  return <div className="administration">{content}</div>;
};

export default Administration;
