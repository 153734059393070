import { Button, Drawer, Form, Spin } from 'antd';
import ProfileEditForm from './forms/profile-edit-form';
import ProfileViewForm from './forms/profile-view-form';
import editIcon from '../../assets/images/custom-icons/edit.svg';
import './styles.css';
import ChangePasswordForm from './forms/change-password-form';
import DeleteUserModal from './forms/delete-user-modal';

const ProfileView = ({
  t,
  user,
  form,
  isEdit,
  editToogle,
  updateUser,
  isOpenProfile,
  isOpenPassword,
  closePasswordModal,
  openPasswordModal,
  isDataLoaded,
  close,
  errorFields,
  roleOptions,
  isCurrentUser,
  showToogleStatusButton,
  isDisabledUser,
  toogleUserStatus,
  onRoleChange,
  userStatusRender,
  userRoleRender,
  reInvite,
  showReInviteButton,
  onAvatarChange,
  isAdmin,
  isOpenDeleteModal,
  openUserDeleteModal,
  closeDeleteUserModal,
  hasChanges,
  onFieldsChange,
}) => (
  <Drawer open={isOpenProfile} width={600} closable={false} onClose={close}>
    <div className="profile">
      <div className="profile-header">
        <div className="profile-header__title">
          <h1>{t('profile.title')}</h1>
          {!isEdit && <img src={editIcon} onClick={editToogle} alt="edit profile" role="presentation" />}
        </div>
        {isEdit && (
          <div className="profile-header__btns">
            {hasChanges ? (
              <>
                <Button style={{ height: 40, background: 'rgba(108, 108, 137, 0.08)' }} onClick={editToogle}>
                  {t('profile.btns.cancel')}
                </Button>
                <Button type="primary" style={{ marginLeft: 8, height: 40 }} onClick={updateUser}>
                  {t('profile.btns.save')}
                </Button>
              </>
            ) : (
              <Button
                style={{ marginLeft: 8, height: 40, background: 'rgba(108, 108, 137, 0.08)' }}
                onClick={editToogle}
              >
                {t('profile.btns.exit')}
              </Button>
            )}
          </div>
        )}
      </div>
      <div className="profile-content">
        {isDataLoaded() ? (
          <Spin size="large" />
        ) : (
          <>
            <Form form={form} onFieldsChange={onFieldsChange}>
              {isEdit ? (
                <ProfileEditForm
                  t={t}
                  user={user}
                  open={openPasswordModal}
                  roleOptions={roleOptions}
                  isCurrentUser={isCurrentUser}
                  isDisabledUser={isDisabledUser}
                  showToogleStatusButton={showToogleStatusButton}
                  toogleUserStatus={toogleUserStatus}
                  onRoleChange={onRoleChange}
                  userStatusRender={userStatusRender}
                  onAvatarChange={onAvatarChange}
                  isAdmin={isAdmin}
                  openUserDeleteModal={openUserDeleteModal}
                />
              ) : (
                <ProfileViewForm
                  t={t}
                  user={user}
                  userStatusRender={userStatusRender}
                  userRoleRender={userRoleRender}
                  reInvite={reInvite}
                  showReInviteButton={showReInviteButton}
                  isCurrentUser={isCurrentUser}
                  isAdmin={isAdmin}
                  openUserDeleteModal={openUserDeleteModal}
                />
              )}
            </Form>
            <ChangePasswordForm t={t} isOpen={isOpenPassword} close={closePasswordModal} errorFields={errorFields} />
            <DeleteUserModal isOpen={isOpenDeleteModal} close={closeDeleteUserModal} user={user} />
          </>
        )}
      </div>
    </div>
  </Drawer>
);

export default ProfileView;
