import dayjs from 'dayjs';

const calendarDateByDayjs = date => {
  if (!date) {
    return null;
  }
  return dayjs(date).isValid() ? dayjs(date) : null;
};

export default calendarDateByDayjs;
