import { Button, Divider, Drawer, Dropdown, Form } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { useSelector } from 'react-redux';
import { CloseOutlined, EllipsisOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { selectIsAdmin } from '../../../../store/slices/user';
import editIcon from '../../../../assets/images/custom-icons/edit-pan.svg';
import AssetInfoFormGeneral from './form/view/general';
import AssetInfoFormInformation from './form/view/information';
import AssetInfoGeneralEditor from './form/editor/general';
import AssetInfoInformationEditor from './form/editor/information';
import AssetInfoFormCompetitive from './form/view/competitive';
import AssetInfoFormCompetitiveEditor from './form/editor/competitive';
import fileIcons from '../../../../common/file-icons';

const AssetInfoView = ({
  isEdit,
  close,
  updateAsset,
  toViewMode,
  toEditMode,
  asset,
  fieldsList,
  updateForm,
  t,
  isOpen,
  products,
  fileOrigins,
  indications,
  tags,
  fileTypes,
  onSelectChange,
  items,
  onDropdownClick,
  category,
  nameIsEmpty,
  categoryIdIsEmpty,
  fileTypeIsEmpty,
  intelligences,
  intelligenceTags,
  getCategoryById,
  users,
  onDropdownChanged,
  categories,
}) => {
  const isAdmin = useSelector(selectIsAdmin);
  const cancel = () => (asset.id ? toViewMode() : close());

  const headerCtrlBtns = () => {
    if (!isAdmin) {
      return (
        <div className="asset-info__header__ctrl-btns">
          <CloseOutlined onClick={close} data-testid="close-asset-drawer-btn" />
        </div>
      );
    }
    if (isEdit) {
      return (
        <div className="asset-info__header__ctrl-btns">
          <Button style={{ height: 40, background: 'rgba(108, 108, 137, 0.08)' }} onClick={cancel}>
            {t('assets.btn.cancel')}
          </Button>
          <Button type="primary" style={{ marginLeft: 8, height: 40 }} onClick={updateAsset}>
            {t(asset.id ? 'assets.btn.save' : 'assets.btn.add_asset')}
          </Button>
        </div>
      );
    }

    return (
      <div className="asset-info__header__ctrl-btns">
        <img
          src={editIcon}
          onClick={toEditMode}
          alt="edit"
          role="presentation"
          style={{ marginRight: isAdmin ? 0 : 20 }}
        />
        {isAdmin && (
          <Dropdown
            menu={{
              items,
              onClick: e => onDropdownClick(e),
            }}
            placement="topRight"
            trigger={['click']}
            overlayStyle={{ width: 245 }}
          >
            <EllipsisOutlined style={{ margin: '0 20px' }} />
          </Dropdown>
        )}
        <CloseOutlined onClick={close} data-testid="close-asset-drawer-btn" />
      </div>
    );
  };

  if (asset.author && fieldsList) {
    return (
      <Drawer
        open={isOpen}
        width={700}
        closable={false}
        onClose={close}
        key={asset.id || 'new-asset-drawer'}
        zIndex={10}
      >
        <div className="asset-info">
          <div className="asset-info__header">
            <div className="asset-info__header__asset-type">
              {asset.fileType && (
                <>
                  <img src={fileIcons(asset.fileTypeId)} alt="asset logo" />
                  <span>{asset.fileType?.name}</span>
                </>
              )}
            </div>
            {headerCtrlBtns()}
          </div>
          {isEdit ? (
            <div className="assets-form--required" style={{ margin: '36px 0 0 -11px', display: 'flex' }}>
              {nameIsEmpty && <ExclamationCircleOutlined style={{ color: 'red', marginRight: 10, fontSize: 32 }} />}
              <TextArea
                bordered={false}
                status="error"
                style={{ minWidth: '100%', maxWidth: 400, fontSize: 32, fontWeight: 900, marginLeft: '-10px' }}
                placeholder="Enter document name"
                defaultValue={asset.name}
                onChange={({ target }) => updateForm({ name: target.value })}
                autoSize={{ minRows: 1, maxRows: 2 }}
              />
            </div>
          ) : (
            <h1 className="asset-info__name">{asset.name}</h1>
          )}

          <div style={{ marginBottom: 50, marginLeft: isEdit ? -11 : 0 }}>
            {isEdit ? (
              <TextArea
                bordered={false}
                style={{ minWidth: '100%', fontSize: 18 }}
                placeholder="Enter document description"
                defaultValue={asset.description}
                onChange={({ target }) => updateForm({ description: target.value })}
                autoSize={{ minRows: 1, maxRows: 2 }}
              />
            ) : (
              asset.description && (
                <p className="asset-info__name" style={{ minWidth: '100%', fontSize: 18 }}>
                  {asset.description}
                </p>
              )
            )}
          </div>
          {asset.downloadLink && (
            <div className="asset-info__download">
              <a href={asset.downloadLink} target="blank">
                <Button type="primary" style={{ height: 40 }}>
                  {t('assets.info.open')}
                </Button>
              </a>
              <TextArea bordered={false} autoSize={{ minRows: 1, maxRows: 2 }} value={asset.link} readOnly />
            </div>
          )}
          <Divider />
          {isEdit ? (
            <Form>
              <div className="asset-info__form-items-wrapper">
                <AssetInfoGeneralEditor
                  t={t}
                  form={fieldsList}
                  fileTypes={fileTypes}
                  onSelectChange={onSelectChange}
                  updateForm={updateForm}
                  fileTypeIsEmpty={fileTypeIsEmpty}
                  categoryIdIsEmpty={categoryIdIsEmpty}
                  users={users}
                  categories={categories}
                  categoryId={category?.id}
                />
                <AssetInfoInformationEditor
                  t={t}
                  form={fieldsList}
                  products={products}
                  fileOrigins={fileOrigins}
                  indications={indications}
                  tags={tags}
                  onSelectChange={onSelectChange}
                  updateForm={updateForm}
                  onDropdownChanged={onDropdownChanged}
                />
                <AssetInfoFormCompetitiveEditor
                  t={t}
                  form={fieldsList}
                  intelligences={intelligences}
                  intelligenceTags={intelligenceTags}
                  onSelectChange={onSelectChange}
                  onDropdownChanged={onDropdownChanged}
                />
              </div>
            </Form>
          ) : (
            <Form>
              <div className="asset-info__form-items-wrapper">
                <AssetInfoFormGeneral
                  t={t}
                  form={fieldsList}
                  categoryName={category?.name ? category.name : getCategoryById(asset.categoryId)?.name}
                />
                <AssetInfoFormInformation t={t} form={fieldsList} />
                <AssetInfoFormCompetitive t={t} form={fieldsList} />
              </div>
            </Form>
          )}
        </div>
      </Drawer>
    );
  }

  return null;
};

export default AssetInfoView;
