import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Spin } from 'antd';
import AssetCard from './asset-card';
import getDate from '../../../common/helpers/calendar/get-date';
import ResizableTable from '../../../components/resizable-table/resizable-table';

const AssetsList = ({ documents, setAsset, fetchAssets, hasMore, updateSorting, isLoading, sortBy, sortOrder }) => {
  const { t } = useTranslation();

  const columns = [
    {
      title: t('assets.table.documents'),
      dataIndex: 'name',
      render: (_, document) => <AssetCard data={document} selectAsset={setAsset} />,
      key: 'name',
      width: '50%',
      resizable: true,
      sorter: true,
    },
    {
      title: t('assets.table.modified'),
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (_, document) => <span>{getDate(document.updatedAt)}</span>,
      sorter: true,
      resizable: true,
    },
    {
      title: t('assets.table.product'),
      dataIndex: 'product',
      key: 'product',
      render: (_, document) => <span>{document.product?.name || 'N/A'}</span>,
      sorter: true,
      resizable: true,
    },
    {
      title: t('assets.table.expiration'),
      dataIndex: 'expirationDate',
      key: 'expirationDate',
      render: (_, document) => <span>{getDate(document.expirationDate)}</span>,
      sorter: true,
      resizable: true,
    },
  ];

  const sort = key => {
    updateSorting(key);
  };

  const loader = useMemo(
    () =>
      isLoading ? (
        <div style={{ width: '100%', textAlign: 'center' }}>
          <Spin />
        </div>
      ) : null,
    [isLoading]
  );

  return (
    <InfiniteScroll
      dataLength={documents.length}
      next={fetchAssets}
      hasMore={hasMore}
      loader={loader}
      height={window.innerHeight - 81}
    >
      <ResizableTable
        rowClassName="clickable"
        columns={columns}
        dataSource={documents}
        pagination={false}
        rowKey={doc => doc.id}
        sort={sort}
        loading={isLoading}
        onRow={data => ({
          onClick: () => setAsset(data),
        })}
        showSorterTooltip={false}
        sortBy={sortBy}
        sortOrder={sortOrder}
      />
    </InfiniteScroll>
  );
};

export default AssetsList;
