import { useEffect, useState } from 'react';
import ApiAssets from '../../../../api/assets';
import ApiUsersManagement from '../../../../api/user-management';

const useAssetConfigParams = () => {
  const [configItems, setConfigItems] = useState({
    products: [],
    fileOrigins: [],
    indications: [],
    tags: [],
    intelligences: [],
    intelligenceTags: [],
    fileTypes: [],
    users: [],
    authors: [],
  });

  const getParams = () => {
    Promise.allSettled([
      ApiAssets.getProducts(),
      ApiAssets.getFileOrigins(),
      ApiAssets.getIndications(),
      ApiAssets.getTags(),
      ApiAssets.getIntelligences(),
      ApiAssets.getIntelligencesTags(),
      ApiAssets.getFileTypes(),
      ApiUsersManagement.getUsers(),
      ApiAssets.getAuthors(),
    ]).then(collection => {
      const assetParam = collection.reduce(
        (acc, item) => ({ ...acc, ...(item?.value?.data ? item.value.data : { users: [] }) }),
        {}
      );
      setConfigItems(assetParam);
    });
  };

  const updateConfigItem = (name, value) => {
    setConfigItems({ ...configItems, [name]: value });
  };

  useEffect(() => {
    getParams();
  }, []);

  return {
    getConfigParams: {
      ...configItems,
    },
    setConfigParams: updateConfigItem,
    refresh: getParams,
  };
};

export default useAssetConfigParams;
