import { useNavigate } from 'react-router-dom';
import './styles.css';

const APP_NAME = window.appName;

const CompanyBrand = ({ logoStyles, nameStyles }) => {
  const navigate = useNavigate();
  return (
    <div className="company-brand" onClick={() => navigate('/')} role="presentation">
      <img className="company-logo" src="/api/images/logo.svg" alt="logo" style={logoStyles} />
      <p className="company-name" style={nameStyles}>
        {APP_NAME}
      </p>
    </div>
  );
};

export default CompanyBrand;
