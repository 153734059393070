import { CloseOutlined, PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { Checkbox, Divider, Dropdown, Input, Tag } from 'antd';
import { useEffect, useState, useMemo } from 'react';
import './styles.css';

const DropdownTagsSelect = ({ options = [], onChange, onCreate, defaultValue = [], placeholder = '+ Add tag' }) => {
  const [selectedOptions, setSelectedOptions] = useState(defaultValue);
  const [dropdownItems, setDropdownItems] = useState([]);
  const [search, setSearch] = useState();

  useEffect(() => {
    if (onChange) {
      onChange(selectedOptions);
    }
  }, [selectedOptions]);

  const createTag = () => {
    const newItem = { name: search, id: search, isNew: true };
    onCreate(newItem);
    setDropdownItems([newItem, ...dropdownItems]);
    setSelectedOptions([newItem, ...selectedOptions]);
    setSearch();
  };

  const updateSearch = e => {
    const searchValue = e.target.value;
    if (searchValue && searchValue.trim().length) {
      setSearch(searchValue);
    } else {
      setSearch(null);
    }
  };

  const remove = option => {
    const result = selectedOptions.filter(item => (item.id || item.value) !== (option.id || option.value));
    setSelectedOptions(result);
  };

  const updateSelectedOptions = option => {
    const hasOptionInFilter = selectedOptions?.filter(
      item => (item.value || item.id) === (option.value || option.id)
    )[0];

    if (hasOptionInFilter) {
      remove(option);
    } else {
      setSelectedOptions([...selectedOptions, option]);
    }
  };

  const items = () => {
    const list = () => {
      if (dropdownItems.length) {
        return dropdownItems.map(item => ({
          key: item.id || item.value,
          label: (
            <div
              key={item.id || item.value}
              style={{ display: 'flex', width: '100%', alignItems: 'center', cursor: 'pointer', marginBottom: 10 }}
              onClick={() => updateSelectedOptions(item)}
              role="presentation"
            >
              <Checkbox checked={selectedOptions.filter(val => (val.id || val.value) === (item.id || item.value))[0]} />
              <span style={{ marginLeft: 10 }}>{item.label || item.name}</span>
            </div>
          ),
        }));
      }

      if (!dropdownItems.length && search && onCreate) {
        return [
          {
            key: 'addNew',
            label: (
              <div style={{ display: 'flex', cursor: 'pointer' }}>
                <PlusOutlined style={{ margin: '0 10px 0 5px', fontSize: 10 }} />
                <span onClick={createTag} role="presentation">
                  Add "{search}"
                </span>
              </div>
            ),
          },
        ];
      }

      return [];
    };

    return [
      {
        key: 'search',
        label: (
          <div style={{ padding: '0 10px', color: '#000' }}>
            <div style={{ display: 'flex' }}>
              <SearchOutlined />
              <Input
                bordered={false}
                value={search}
                placeholder={onCreate ? 'Select or create tag' : 'Select tag'}
                onChange={updateSearch}
              />
            </div>
            <Divider style={{ margin: '0 0 10px 0' }} />
          </div>
        ),
      },
      ...list(),
    ];
  };

  const tagsList = useMemo(
    () =>
      selectedOptions.map(item => (
        <Tag
          key={item.id || item.value}
          style={{
            marginRight: 3,
            cursor: 'default',
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span>{item.name || item.label}</span>
            <CloseOutlined
              onClick={() => remove(item)}
              style={{ marginLeft: 5, fontSize: 10, cursor: 'pointer', color: 'rgba(108, 108, 137, 1)' }}
            />
          </div>
        </Tag>
      )),
    [selectedOptions]
  );

  useEffect(() => {
    setDropdownItems(
      search
        ? options.filter(option => (option.label || option.name)?.toLowerCase().includes(search?.toLowerCase()))
        : options
    );
  }, [search]);

  useEffect(() => {
    setSearch();
    setDropdownItems(
      options.filter(
        (option, index, self) =>
          option.label ||
          option.name ||
          index === self.findIndex(p => p.name === option.name || p.label === option.label)
      )
    );
  }, [options]);

  return (
    <>
      {tagsList}
      <Dropdown
        menu={{
          items: items(),
          onClick: e => {
            e.preventDefault();
          },
        }}
        trigger={['click']}
        overlayClassName="dropdown-tag-select"
      >
        <span style={{ marginLeft: 10, color: 'rgba(178, 178, 199, 1)', cursor: 'pointer', fontSize: 12 }}>
          {placeholder}
        </span>
      </Dropdown>
    </>
  );
};

export default DropdownTagsSelect;
