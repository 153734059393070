import moment from 'moment';

const getModifieDate = date => {
  if (!date) {
    return null;
  }

  const time = moment.utc(date).format('LT');
  const formattedDate = moment.utc(date).format('DD MMM YYYY');

  return `${formattedDate}, ${time}`;
};

export default getModifieDate;
