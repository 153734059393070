import { BellOutlined, CloseOutlined } from '@ant-design/icons';
import { Badge, Divider, Dropdown } from 'antd';
import { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ApiNotifications from '../../api/notifications';
import { setCurrentAsset } from '../../store/slices/assets';
import { selectCategories } from '../../store/slices/categories';
import NotificationsList from './components/list';
import './styles.css';

const NotificationMessages = () => {
  const [items, setItems] = useState([]);
  const [open, setOpen] = useState(false);
  const [showFull, setShowFull] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const categories = useSelector(selectCategories);

  const openDropdown = () => {
    setOpen(true);
  };

  const selectAsset = (asset, notificationId) => {
    const updatedList = items.map(i => (i.id === notificationId ? { ...i, viewed: true } : i));
    setItems(updatedList);
    ApiNotifications.setReadNotifications([notificationId]);
    dispatch(setCurrentAsset(asset));
    navigate(`assets/asset/${asset.id}`);
  };

  const getNotifications = () => {
    ApiNotifications.getNotifications().then(r => {
      setItems(r.data.notifications);
    });
  };

  const unreadableNotifications = useMemo(() => items.filter(item => !item.viewed), [items]);

  const unreadableNotificationsCount = useMemo(() => unreadableNotifications.length, [items]);

  const notificationToogler = useMemo(
    () => (
      <div className="notifications-messages__btn-box" role="presentation" onClick={openDropdown}>
        <div>
          <BellOutlined alt="notification-btn" className="notification-btn" />
        </div>
      </div>
    ),
    [unreadableNotificationsCount]
  );

  const closeDropdown = () => {
    setOpen(false);
  };

  const showMore = () => {
    setShowFull(true);
  };

  const collapse = () => {
    setShowFull(false);
  };

  const readAll = () => {
    ApiNotifications.setReadNotifications(unreadableNotifications.map(n => n.id)).then(() => {
      setItems(items.map(i => ({ ...i, viewed: true })));
    });
  };

  const dropdownContent = useMemo(() => {
    const notificationsList = showFull ? items : items.slice(0, 3);

    return (
      <div className="notifications-messages__dropdown-content__box">
        <div className="notifications-messages__dropdown-content__header">
          <h4>{t('notifications.title')}</h4>
          <CloseOutlined onClick={closeDropdown} data-testid="close-notification-messages-btn" />
        </div>
        <Divider style={{ marginTop: 0, marginBottom: 10 }} />
        {items.length ? (
          <>
            <NotificationsList
              items={notificationsList}
              selectAsset={selectAsset}
              categories={categories}
              t={t}
              showFull={showFull}
            />
            <div className="notifications-messages__show-more-box">
              {items.length < 4 && <div />}
              {!showFull && items.length > 3 && (
                <p onClick={showMore} role="presentation" className="notifications-messages__show-more-btns">
                  {t('notifications.more')}
                </p>
              )}
              {showFull && (
                <p onClick={collapse} role="presentation" className="notifications-messages__show-more-btns">
                  {t('notifications.hide')}
                </p>
              )}
              <p onClick={readAll} role="presentation" className="notifications-messages__show-more-btns">
                {t('notifications.read_all')}
              </p>
            </div>
          </>
        ) : (
          <p className="notifications-messages__empty">{t('notifications.empty')}</p>
        )}
      </div>
    );
  }, [items, categories, showFull]);

  useEffect(() => {
    getNotifications();

    const setRefreshInterval = setInterval(() => {
      getNotifications();
    }, 15000);

    return () => clearInterval(setRefreshInterval);
  }, []);

  return (
    <div className="notifications-messages">
      <Dropdown open={open} dropdownRender={() => dropdownContent} overlayStyle={{ zIndex: 5 }} placement="topRight">
        {unreadableNotificationsCount ? (
          <Badge count={unreadableNotificationsCount} size="small" color="geekblue" style={{ cursor: 'default' }}>
            {notificationToogler}
          </Badge>
        ) : (
          notificationToogler
        )}
      </Dropdown>
    </div>
  );
};

export default NotificationMessages;
