import { createSlice } from '@reduxjs/toolkit';
import ApiTrails from '../../api/trail';

export const AuditTrailsSlice = createSlice({
  name: 'trails',
  initialState: {
    trails: [],
    hasMore: false,
    isLoading: false,
  },
  reducers: {
    setTrails: (state, action) => ({
      ...state,
      trails: [...state.trails, ...action.payload],
      hasMore: action.payload?.length && action.payload?.length === 30,
      isLoading: false,
    }),
    resetTrails: state => ({ ...state, trails: [], hasMore: false, isLoading: false }),
    setLoading: state => ({ ...state, isLoading: true }),
  },
});

export const { setTrails, resetTrails, setLoading } = AuditTrailsSlice.actions;

export const fetchTrails = query => dispatch => {
  dispatch(setLoading());
  ApiTrails.getTrails(query).then(r => {
    dispatch(setTrails(r.data.trails));
  });
};

export const clearTrails = () => dispatch => {
  dispatch(resetTrails());
};

export const selectTrails = state => state.trails.trails;
export const selecthasMoreTrails = state => state.trails.hasMore;
export const selectTrailsLoading = state => state.trails.isLoading;

export default AuditTrailsSlice.reducer;
