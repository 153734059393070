import axios from 'axios';

const login = credentials => axios.post('api/v1/auth/login', credentials);

const newPassword = ({ password, token }) =>
  axios.put('api/v1/user/update-password', { password }, { headers: { Authorization: 'Bearer ' + token } });

const resetPassword = email => axios.post('api/v1/user/reset-password', { email });

const logout = () => {
  axios.post('api/v1/auth/logout');
  localStorage.removeItem('user_token');
};

const loginByToken = token => axios.get('api/v1/user', { headers: { Authorization: 'Bearer ' + token } });

const updatePassword = (oldPassword, password) => axios.put('api/v1/user/update-password', { password, oldPassword });

const checkToken = token => axios.post('api/v1/auth/check-token', { token });

const ApiAuth = {
  login,
  newPassword,
  resetPassword,
  logout,
  loginByToken,
  updatePassword,
  checkToken,
};
export default ApiAuth;
