import { Avatar, Form } from 'antd';
import fileIcons from '../../../../../../common/file-icons';
import getModifieDate from '../../../../../../common/helpers/calendar/get-modify-date';
import useNotification from '../../../../../../components/notification/notification';

const AssetInfoFormGeneral = ({ t, form, categoryName }) => {
  const modifie = getModifieDate(form.updatedAt);
  const { openNotification } = useNotification();

  const trimmedString = string => (string && string.length > 40 ? string.substring(0, 40) + '...' : string);

  const copyLink = () => {
    if (form.link) {
      navigator.clipboard.writeText(form.link);
      openNotification({
        type: 'success',
        message: <p>Link was copied to clipboard!</p>,
      });
    }
  };

  return (
    <>
      <Form.Item name="version">
        <div>
          <p>{t('assets.info.form.category')}</p>
          <span>{categoryName}</span>
        </div>
      </Form.Item>
      <Form.Item name="version">
        <div>
          <p>{t('assets.info.form.version')}</p>
          <span>{form.version || 'N/A'}</span>
        </div>
      </Form.Item>
      <Form.Item name="link">
        <div>
          <p>{t('assets.info.form.source')}</p>
          <span style={{ width: 350, cursor: 'pointer' }} onClick={copyLink} role="presentation">
            {trimmedString(form.link) || '—'}
          </span>
        </div>
      </Form.Item>
      {modifie && (
        <Form.Item name="modified">
          <div>
            <p>{t('assets.info.form.modified')}</p>
            <span>{modifie}</span>
          </div>
        </Form.Item>
      )}
      <Form.Item name="fileType">
        <div>
          <p>{t('assets.info.form.type')}</p>
          <img src={fileIcons(form.fileTypeId)} style={{ width: 20, marginRight: 10 }} alt="file type" />
          <span>{form?.fileType?.name || 'N/A'}</span>
        </div>
      </Form.Item>
      <Form.Item name="author">
        <div>
          <p>{t('assets.info.form.author')}</p>
          <div>
            {form.author.avatar && <Avatar src={form.author.avatar} size={20} style={{ marginRight: 10 }} />}
            <span>
              {form.author.firstName} {form.author.lastName}
            </span>
          </div>
        </div>
      </Form.Item>
    </>
  );
};

export default AssetInfoFormGeneral;
