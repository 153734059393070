import { CheckOutlined, CloseOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Popconfirm } from 'antd';

const AdditionalBtns = ({ isEditMode, id, name, style, toogleEdit, save, cancel, remove }) => (
  <div style={style}>
    {isEditMode && <CheckOutlined style={{ marginRight: 10, cursor: 'pointer' }} onClick={save} />}
    {isEditMode && <CloseOutlined style={{ marginRight: 10, cursor: 'pointer' }} onClick={cancel} />}
    {!isEditMode && <EditOutlined style={{ marginRight: 10, cursor: 'pointer' }} onClick={toogleEdit} />}
    <Popconfirm
      title={`Delete ${name}?`}
      description={`"${name}" will be deleted permanently!`}
      onConfirm={() => remove(id)}
      okText="Yes"
      cancelText="No"
    >
      <DeleteOutlined style={{ marginRight: 10, cursor: 'pointer' }} />
    </Popconfirm>
  </div>
);

export default AdditionalBtns;
