import { Avatar, Divider, Select } from 'antd';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import ApiUsersManagement from '../../../api/user-management';
import { fetchUsers, selectUsers } from '../../../store/slices/user_management';
import { selectUser } from '../../../store/slices/user';
import useNotification from '../../../components/notification/notification';
import ModalWrapper from '../../../components/modal/modal';
import '../styles.css';

const DeleteUserModal = ({ isOpen, close, user }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currentUser = useSelector(selectUser);
  const users = useSelector(selectUsers);
  const [contentAuthor, setContentAuthor] = useState(currentUser.id);
  const { openNotification } = useNotification();

  const authorsOptions = useMemo(
    () =>
      users.map(item => (
        <Select.Option name={item.name} value={item.id} key={item.id} style={{ display: 'flex', alignItems: 'center' }}>
          {item.avatar && <Avatar src={item.avatar} size={20} style={{ marginRight: 10 }} />}
          {item?.firstName} {item?.lastName}
        </Select.Option>
      )),
    [users]
  );

  const deleteUser = () => {
    const { id } = user;
    ApiUsersManagement.deleteUser(id, contentAuthor)
      .then(() => {
        openNotification({
          type: 'success',
          message: <p>{t('profile.notification.delete.success')}</p>,
        });

        dispatch(fetchUsers());

        close();
      })
      .catch(() => {
        openNotification({
          type: 'error',
          message: <p>{t('profile.notification.delete.error')}</p>,
        });
      });
  };

  return (
    <ModalWrapper
      isOpen={isOpen}
      title={t('profile.modal.delete_user.title', { userName: `${user.firstName} ${user.lastName}` })}
      onOk={() => deleteUser()}
      onCancel={() => close()}
      okText={t('profile.modal.delete_user.delete')}
      okButtonProps={{
        size: 'large',
      }}
      cancelButtonProps={{
        size: 'large',
        style: { backgroundColor: 'rgba(108, 108, 137, 0.08)' },
      }}
    >
      <Divider />
      <div className="profile__modal__delete-user__content-box">
        <p className="profile__modal__delete-user__new-author-title">
          {t('profile.modal.delete_user.select_new_author')}
        </p>
        <Select value={contentAuthor} onChange={id => setContentAuthor(id)} placeholder="Select new content author">
          {authorsOptions}
        </Select>
      </div>
      <Divider />
    </ModalWrapper>
  );
};

export default DeleteUserModal;
