import { Spin } from 'antd';
import '../styles.css';

const LoadingPage = ({ isLoading }) => (
  <div className="dashboard__loading-page" style={{ backgroundColor: isLoading ? '#fff' : 'transparent' }}>
    <Spin className="dashboard__loading-page__spinner" />
  </div>
);

export default LoadingPage;
