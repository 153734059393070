import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment/moment';
import AssetInfo from './components/asset-info/asset-info';
import AssetsHeader from './components/asset-header';
import AssetsList from './components/assets-list';
import { selectUser } from '../../store/slices/user';
import SearchGlobal from './components/search-global/search-global';
import {
  clearAssets,
  fetchAllAssets,
  fetchAssetsByCategory,
  selectAssets,
  selectHasMoreAssets,
  setCurrentAsset,
} from '../../store/slices/assets';
import { selectCategories } from '../../store/slices/categories';
import useAssetConfigParams from './components/hooks/assetConfigParams';
import ApiDashboard from '../../api/dashboard';

const Assets = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { categoryId } = useParams();
  const assets = useSelector(selectAssets);
  const categories = useSelector(selectCategories);
  const currentUser = useSelector(selectUser);
  const hasMore = useSelector(selectHasMoreAssets);
  const [searchValue, setSearchValue] = useState();
  const [filterValues, setFilterValues] = useState();
  const [page, setPage] = useState(1);
  const [isLoading, setLoading] = useState(false);
  const [category, setCategory] = useState();
  const [sortOrder, setSortOrder] = useState();
  const [sortBy, setSortBy] = useState();
  const location = useLocation();
  const { getConfigParams, setConfigParams, refresh } = useAssetConfigParams();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const isExpiredPage = pathname === '/assets/expired';
  const isAboutToExpirePage = pathname === '/assets/about-to-expire';
  const isLastCreatedPage = pathname === '/assets/last-created';

  const expiration = () => {
    if (isExpiredPage && !(filterValues || {}).expiration) {
      return {
        expirationDateEnd: moment(),
      };
    }

    if (isAboutToExpirePage && !filterValues.expiration) {
      return {
        expirationDateStart: moment().local(),
        expirationDateEnd: moment().local().add(91, 'days'),
      };
    }

    return {
      expirationDateStart: filterValues.expiration?.[filterValues.expiration.length - 1]?.start,
      expirationDateEnd: filterValues.expiration?.[filterValues.expiration.length - 1]?.end,
    };
  };

  const fetchAssets = (currentPage = page) => {
    let params = {
      name: searchValue,
      ...filterValues,
      updatedAtStart: filterValues.period?.[filterValues.period.length - 1]?.start,
      updatedAtEnd: filterValues.period?.[filterValues.period.length - 1]?.end,
      createdAtStart: filterValues.createdPeriod?.[filterValues.createdPeriod.length - 1]?.start,
      createdAtEnd: filterValues.createdPeriod?.[filterValues.createdPeriod.length - 1]?.end,
      sortBy,
      sortOrder,
    };

    params = { ...params, ...expiration() };

    if (isLastCreatedPage) {
      params = {
        ...params,
        ...{
          createdAtStart: moment().local().subtract(30, 'days'),
          createdAtEnd: moment().local(),
          sortBy: 'createdAt',
        },
      };
    }

    if ((categoryId && !isLoading) || (categoryId && searchValue)) {
      setLoading(true);
      dispatch(fetchAssetsByCategory(categoryId, currentPage, { ...params }));
      setPage(currentPage + 1);
    }

    if ((!categoryId && !isLoading) || (!categoryId && searchValue)) {
      setLoading(true);
      dispatch(
        fetchAllAssets({
          page: currentPage,
          ...params,
        })
      );
      setPage(currentPage + 1);
    }
  };

  const initAssetComponent = () => {
    if (assets?.length) {
      dispatch(clearAssets());
    }

    fetchAssets(1);
  };

  const updateFilterValue = filter => {
    setFilterValues(filter);
  };

  const updateSearchValue = e => {
    const { value } = e.target;
    if (value && value.trim().length) {
      setSearchValue(value);
    } else {
      setSearchValue(null);
    }
  };

  const setAsset = asset => {
    dispatch(setCurrentAsset(asset));
    ApiDashboard.postOpenedAsset(asset.id);

    if (categoryId) {
      navigate(`/assets/category/${categoryId}/asset/${asset.id}`);
    } else {
      navigate(`/assets/asset/${asset.id}`);
    }
  };

  const updateSorting = key => {
    setSortBy(key);

    if (sortOrder === 'ASC') {
      setSortOrder('DESC');
      return;
    }

    if (sortOrder === 'DESC') {
      setSortOrder('ASC');
      return;
    }

    setSortOrder('ASC');
  };

  useEffect(() => {
    if (assets.length) {
      dispatch(clearAssets());
    }
    setPage(1);
    setSearchValue();
    setFilterValues({});
    setSortBy();
    setSortOrder();
    if (!categoryId && category) {
      setCategory();
    }
  }, [categoryId]);

  useEffect(() => {
    if (assets.length) {
      dispatch(clearAssets());
    }

    let getAssetsWithDelay;

    clearTimeout(getAssetsWithDelay);

    getAssetsWithDelay = setTimeout(() => {
      setPage(1);
      initAssetComponent();
    }, 1000);

    return () => clearTimeout(getAssetsWithDelay);
  }, [searchValue, filterValues, sortOrder]);

  useEffect(() => {
    setCategory(categories.filter(item => item.id === +categoryId)[0]);
  }, [categories, categoryId]);

  useEffect(() => {
    setLoading(false);
  }, [assets]);

  if (location.pathname === '/assets/search') {
    return (
      <div className="assets">
        <SearchGlobal
          value={searchValue}
          onChange={updateSearchValue}
          updateFilterValue={updateFilterValue}
          categories={categories}
          setAsset={setAsset}
          searchValue={searchValue}
          filterValues={filterValues}
          assets={assets}
          fetchAssets={fetchAssets}
          hasMore={hasMore}
          updateSorting={updateSorting}
          isLoading={isLoading}
          sortBy={sortBy}
          sortOrder={sortOrder}
        />
      </div>
    );
  }

  return (
    <>
      <div className="assets">
        <AssetsHeader
          category={category}
          setAsset={setAsset}
          t={t}
          author={currentUser}
          value={searchValue}
          onChange={updateSearchValue}
          updateFilterValue={updateFilterValue}
          filters={filterValues}
          getConfigParams={getConfigParams}
        />

        <AssetsList
          documents={assets}
          setAsset={setAsset}
          fetchAssets={fetchAssets}
          hasMore={hasMore}
          updateSorting={updateSorting}
          isLoading={isLoading}
          sortBy={sortBy}
          sortOrder={sortOrder}
        />
      </div>
      <AssetInfo
        category={category}
        categoryId={+categoryId}
        categories={categories}
        fetchAssets={initAssetComponent}
        getConfigParams={getConfigParams}
        setConfigParams={setConfigParams}
        refresh={refresh}
      />
    </>
  );
};
export default Assets;
