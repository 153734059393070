const ResizableTableCell = ({
  cellProps,
  handleResize,
  resizeCell = {},
  updateResizeCell,
  handleRef,
  cellRef,
  resizable,
  sort,
  columns,
}) => {
  const { index, children, ...restProps } = cellProps;

  const getDistanceFromLeft = node => node.getBoundingClientRect().left + window.pageXOffset;

  const onMouseDown = e => {
    const { pageX } = e;
    const nodeWidth = pageX + 15 - getDistanceFromLeft(cellRef.current[index]);

    updateResizeCell(index);
    handleResize(index, nodeWidth);
  };

  const onMouseMove = e => {
    const { pageX } = e;
    const { cellIndex } = resizeCell;

    if (cellIndex > -1 && index === cellIndex) {
      const newWidth = pageX + 15 - getDistanceFromLeft(cellRef.current[cellIndex]);
      handleResize(index, newWidth);
    }
  };

  const onMouseUp = () => {
    if (resizeCell.cellIndex > -1) {
      updateResizeCell(-1);
      return;
    }

    if (sort) {
      sort(columns[index].key);
    }
  };

  const onBlur = () => {
    updateResizeCell(-1);
  };

  return (
    <th
      {...restProps}
      style={{ ...restProps.style }}
      onMouseMove={onMouseMove}
      onMouseUp={onMouseUp}
      ref={node => handleRef(node, index)}
      onBlur={onBlur}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          zIndex: 0,
          width: '100%',
          minWidth: 'max-content',
        }}
      >
        {children}
        {resizable && (
          <div
            style={{
              position: 'absolute',
              right: 0,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: 30,
              height: '100%',
              cursor: 'col-resize',
              zIndex: 1,
              textAlign: 'center',
            }}
            onMouseDown={onMouseDown}
            role="presentation"
          >
            <div style={{ width: 1, height: 30, background: 'rgba(108, 108, 137, 0.16)' }} />
          </div>
        )}
      </div>
    </th>
  );
};

export default ResizableTableCell;
