import { CloseOutlined } from '@ant-design/icons';
import { DatePicker, Dropdown, Tag } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const Period = ({ field, onChange }) => {
  const { pathname } = useLocation();
  const [period, setPeriod] = useState({ start: null, end: null });
  const [open, setOpen] = useState(true);

  const updatePeriod = date => {
    setPeriod({ start: moment(date[0].$d).toISOString(), end: moment(date[1].$d).toISOString() });
    onChange(field.id, { start: moment(date[0].$d).toISOString(), end: moment(date[1].$d).toISOString() });
  };

  const getDropdownItem = () => [
    {
      key: field.id,
      label: (
        <DatePicker.RangePicker
          onChange={dd => {
            updatePeriod(dd);
          }}
          format="DD.MM.YYYY"
        />
      ),
    },
  ];

  useEffect(() => {
    setPeriod({ start: null, end: null });
  }, [pathname]);

  const openDropdown = () => {
    setOpen(true);
  };

  const close = visible => {
    if (!visible) {
      setOpen(visible);
    }
  };

  return (
    <Dropdown
      menu={{ items: getDropdownItem(), onClick: e => e.preventDefault() }}
      overlayStyle={{ maxHeight: 320, overflow: 'auto', boxShadow: '0px 20px 140px 0px rgba(0, 0, 0, 0.15)' }}
      trigger={['click']}
      open={open}
      onOpenChange={visible => close(visible)}
    >
      <Tag
        style={{
          fontSize: 14,
          border: 'none',
          background: 'rgba(108, 108, 137, 0.08)',
          padding: '5px 10px',
          marginTop: 5,
          cursor: 'pointer',
        }}
        onClick={openDropdown}
      >
        <span style={{ fontWeight: 600 }}>{field.name}: </span>
        <span style={{ color: 'rgba(108, 108, 137, 1)' }}>
          {period.start && `${moment(period.start).format('DD MMM YY')} to ${moment(period.end).format('DD MMM YY')}`}
        </span>
        <span
          style={{ cursor: 'pointer', color: 'rgba(178, 178, 199, 1)', marginLeft: 10 }}
          onClick={() => onChange(field.id)}
          role="presentation"
        >
          <CloseOutlined style={{ fontSize: 12 }} />
        </span>
      </Tag>
    </Dropdown>
  );
};

export default Period;
