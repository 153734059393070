import { Divider, Form, Input, Select } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import ApiUsersManagement from '../../../api/user-management';
import { fetchUsers } from '../../../store/slices/user_management';
import ModalWrapper from '../../modal/modal';
import './styles.css';
import useNotification from '../../notification/notification';

const InviteUser = ({ isOpen, close }) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [userRole, setUserRole] = useState('user');
  const { openNotification } = useNotification();
  const [inProgress, setProgress] = useState(false);

  const inviteUser = () => {
    const newUser = form.getFieldsValue();

    setProgress(true);

    ApiUsersManagement.invite({
      ...newUser,
      role: userRole,
    })
      .then(() => {
        openNotification({
          type: 'success',
          message: <p>{t('invite_user.notification.success', { email: newUser.email })}</p>,
        });
      })
      .catch(e => {
        openNotification({
          type: 'error',
          message: <p>{t(`invite_user.notification.${e?.response?.data?.error?.code || 'error'}`)}</p>,
        });
      })
      .finally(() => {
        dispatch(fetchUsers());
        setProgress(false);
        close(form.resetFields());
      });
  };

  const roleOptions = [
    {
      label: 'Administator',
      value: 'admin',
    },
    {
      label: 'User',
      value: 'user',
    },
  ];

  const formItems = ['firstName', 'lastName', 'email', 'role'].map(key => {
    if (key === 'role') {
      return (
        <Form.Item key={key} name={key}>
          <div className="invite-user__field">
            <p className="invite-user__field__name">{t(`invite_user.${key}`)}</p>
            <Select
              bordered={false}
              options={roleOptions}
              defaultValue={roleOptions[1]}
              onSelect={role => setUserRole(role)}
            />
          </div>
        </Form.Item>
      );
    }
    return (
      <Form.Item key={key} name={key}>
        <div className="invite-user__field">
          <p className="invite-user__field__name">{t(`invite_user.${key}`)}</p>
          <Input bordered={false} placeholder={t(`invite_user.placeholders.${key}`)} />
        </div>
      </Form.Item>
    );
  });

  return (
    <ModalWrapper
      isOpen={isOpen}
      title={t('invite_user.title')}
      onOk={() => inviteUser()}
      onCancel={() => close()}
      okText={t('invite_user.invite')}
      okButtonProps={{
        size: 'large',
      }}
      cancelButtonProps={{
        size: 'large',
        style: { backgroundColor: 'rgba(108, 108, 137, 0.08)' },
      }}
      confirmLoading={inProgress}
    >
      <Divider />
      <Form form={form}>{formItems}</Form>
      <Divider />
    </ModalWrapper>
  );
};

export default InviteUser;
