import { SearchOutlined } from '@ant-design/icons';
import { Input } from 'antd';
import classNames from 'classnames';
import DropdownFilters from '../../../../components/dropdown-filters/dropdown-filters';
import AssetInfo from '../asset-info/asset-info';
import AssetsList from '../assets-list';
import Placeholder from '../../../../components/placeholder/placeholder';
import useAssetConfigParams from '../hooks/assetConfigParams';

const SearchGlobal = ({
  value,
  onChange,
  categories,
  updateFilterValue,
  searchValue,
  filterValues,
  setAsset,
  assets,
  fetchAssets,
  hasMore,
  updateSorting,
  isLoading,
  sortBy,
  sortOrder,
}) => {
  const { getConfigParams, refresh, setConfigParams } = useAssetConfigParams();
  const { products, fileOrigins, indications, tags, intelligences, intelligenceTags, fileTypes, authors } =
    getConfigParams;

  const items = [
    { name: 'File Type', id: 'fileTypeId', children: fileTypes },
    { name: 'Modified Date', id: 'period', period: true },
    { name: 'Author', id: 'userId', children: authors },
    { name: 'Information' },
    {
      name: 'Category',
      id: 'categoryId',
      children: [...categories].sort((a, b) => {
        if (a.name.toLowerCase() < b.name.toLowerCase()) {
          return -1;
        }
        if (a.name.toLowerCase() > b.name.toLowerCase()) {
          return 1;
        }
        return 0;
      }),
    },
    { name: 'PRC Approved', id: 'prcApproved' },
    { name: 'MRC Approved', id: 'mrcApproved' },
    { name: 'Expiration Date', id: 'expiration', period: true },
    { name: 'File Origin', id: 'fileOriginId', children: fileOrigins },
    { name: 'Products', id: 'productId', children: products },
    { name: 'HEOR Research', id: 'heorResearch' },
    { name: 'Tags', id: 'tag', children: tags },
    { name: 'Additional Indications', id: 'indication', children: indications },
    { name: 'Competitive Intelligence' },
    { name: 'Products', id: 'intelligence', children: intelligences },
    { name: 'Tags', id: 'intelligenceTag', children: intelligenceTags },
  ];

  const hasContent = (searchValue && searchValue.trim().length) || (filterValues && Object.keys(filterValues).length);

  return (
    <>
      <div
        // className="asset-header-search"
        className={classNames('asset-header-search', {
          'asset-header-search--empty': !hasContent,
        })}
      >
        <div className="asset-header-search__wrapper">
          <Input
            value={value}
            onChange={onChange}
            placeholder="Search"
            size="large"
            prefix={<SearchOutlined style={{ color: 'rgba(108, 108, 137, 1)', marginRight: 10 }} />}
            className="asset-header-search__input"
          />
          <div className="assets-filters">
            <DropdownFilters filtersBy={items} updateFilterValue={updateFilterValue} />
          </div>
        </div>
      </div>

      {hasContent ? (
        <AssetsList
          documents={assets}
          setAsset={setAsset}
          fetchAssets={fetchAssets}
          hasMore={hasMore}
          updateSorting={updateSorting}
          isLoading={isLoading}
          sortBy={sortBy}
          sortOrder={sortOrder}
        />
      ) : (
        <Placeholder description={`Use filters or search\n by metadata keywords`} />
      )}

      <AssetInfo
        categories={categories}
        getConfigParams={getConfigParams}
        refresh={refresh}
        setConfigParams={setConfigParams}
        fetchAssets={fetchAssets}
      />
    </>
  );
};

export default SearchGlobal;
