import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import ApiAdministration from '../../../api/administration';
import ApiAssets from '../../../api/assets';
import useNotification from '../../../components/notification/notification';
import AdministrationManagementDnDList from '../components/dnd-list/dnd-list';
import { fetchAllDomains } from '../../../store/slices/domains';
import { fetchAllCategories } from '../../../store/slices/categories';

const Category = () => {
  const { t } = useTranslation();
  const { openNotification } = useNotification();
  const [categories, setCategories] = useState([]);
  const dispatch = useDispatch();

  const updateCategoryDomain = items => {
    let domainId;

    return items.map(c => {
      if (c.domain) {
        domainId = c.id;
        return c;
      }

      if (domainId !== c.domainId) {
        return { ...c, domainId };
      }

      return c;
    });
  };

  const getCategories = () =>
    ApiAssets.getAllDomains().then(r => {
      const normalizeTree = r.data.domains.reduce(
        (acc, domain) => [...acc, { ...domain, domain: true }, ...domain.Categories],
        []
      );

      setCategories(normalizeTree);
      return normalizeTree;
    });

  const createCategory = newCategory =>
    ApiAdministration.createCategory(newCategory).then(() => {
      dispatch(fetchAllDomains());
      dispatch(fetchAllCategories());

      openNotification({
        type: 'success',
        message: <p>{`Category "${newCategory.name}" was created`}</p>,
      });
    });

  const saveCategories = items => {
    const data = updateCategoryDomain(items).filter(c => !c.domain);
    const order = updateCategoryDomain(items)
      .filter(c => !c.domain)
      .map(category => category.id);

    return ApiAdministration.updateCategories({ data, order })
      .then(() => {
        dispatch(fetchAllDomains());
        dispatch(fetchAllCategories());

        openNotification({
          type: 'success',
          message: <p>{t('administration.category.updated')}</p>,
        });
      })
      .catch(() => {
        openNotification({
          type: 'error',
          message: <p>{t('administration.category.updated_error')}</p>,
        });
      });
  };

  const removeCategory = id =>
    ApiAdministration.deleteCategory(id)
      .then(() => {
        dispatch(fetchAllDomains());

        openNotification({
          type: 'success',
          message: <p>{`Category ${categories.filter(c => c.id === id)[0].name} was deleted`}</p>,
        });
      })
      .catch(() => {
        openNotification({
          type: 'error',
          message: <p>{`Category ${categories.filter(c => c.id === id)[0].name} is not empty and can't be deleted`}</p>,
        });
      });

  return (
    <AdministrationManagementDnDList
      fetchData={getCategories}
      save={saveCategories}
      create={createCategory}
      remove={removeCategory}
      translationPath="administration.category"
    />
  );
};

export default Category;
