import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const Summary = ({ total, expired, aboutToExpire }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const toAssetsPage = index => {
    switch (index) {
      case 0:
        navigate('/assets');
        break;
      case 1:
        navigate('/assets/expired');
        break;
      case 2:
        navigate('/assets/about-to-expire');
        break;
      default:
        break;
    }
  };

  const items = [
    { name: t('dashboard.summary.total'), count: total || 0, width: 100, testId: 'summary-total' },
    { name: t('dashboard.summary.expired'), count: expired || 0, width: 120, testId: 'summary-expired' },
    { name: t('dashboard.summary.aboutToExpire'), count: aboutToExpire, width: 160, testId: 'summary-aboutToExpire' },
  ].map((item, index) => (
    <div
      key={index}
      className="dashboard__summary__item-container"
      style={{ width: item.width }}
      onClick={() => toAssetsPage(index)}
      role="presentation"
      data-testid={item.testId}
    >
      <p className="dashboard__summary__item-counter">{item.count}</p>
      <p className="dashboard__summary__item-name">{item.name}</p>
    </div>
  ));

  return <div className="dashboard__summary-container">{items}</div>;
};

export default Summary;
