import axios from 'axios';

const getAllCategories = () => axios.get('api/v1/categories');

const getAllDomains = () => axios.get('api/v1/domains');

const getAllDocuments = (query = {}) => {
  let link = 'api/v1/assets';

  const queryKeys = Object.keys(query);
  if (queryKeys.length) {
    link = 'api/v1/assets?';
    for (let i = 0; i < queryKeys.length; i = i + 1) {
      if (query[queryKeys[i]]) {
        link = Array.isArray(query[queryKeys[i]])
          ? query[queryKeys[i]].reduce((acc, item) => `${acc}${queryKeys[i]}[]=${item}&`, `${link}`)
          : `${link}${queryKeys[i]}=${query[queryKeys[i]]}&`;
      }
    }
  }
  return axios.get(link);
};

const getAssetsByCategory = (categoryId, page, query = {}) => {
  let link = `api/v1/assets?categoryId=${categoryId}`;
  if (page) {
    link = `${link}&page=${page}`;
  }

  const queryKeys = Object.keys(query);
  if (queryKeys.length) {
    for (let i = 0; i < queryKeys.length; i = i + 1) {
      if (query[queryKeys[i]]) {
        link = `${link}&${queryKeys[i]}=${query[queryKeys[i]]}`;
      }
    }
  }

  return axios.get(link);
};

const getAssetById = id => axios.get(`api/v1/assets/${id}`);

const getProducts = () => axios.get('api/v1/products');

const getFileOrigins = () => axios.get('api/v1/fileorigins');

const getFileTypes = () => axios.get('api/v1/filetypes');

const getIndications = () => axios.get('api/v1/indications');

const getTags = () => axios.get('api/v1/tags');

const getIntelligences = () => axios.get('api/v1/intelligences');

const getIntelligencesTags = () => axios.get('api/v1/intelligence-tags');

const getAuthors = () => axios.get('api/v1/authors');

const create = asset => axios.post('api/v1/assets', asset);

const update = (id, asset) => axios.put(`api/v1/assets/${id}`, asset);

const remove = id => axios.delete(`api/v1/assets/${id}`);

const ApiAssets = {
  getAllCategories,
  getAllDomains,
  getAllDocuments,
  getAssetsByCategory,
  getProducts,
  getFileOrigins,
  getFileTypes,
  getIndications,
  getTags,
  getIntelligences,
  getIntelligencesTags,
  getAuthors,
  create,
  update,
  remove,
  getAssetById,
};

export default ApiAssets;
