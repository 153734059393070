import { Checkbox, Dropdown } from 'antd';
import { useEffect, useState, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import MultipleFilter from './Filters/multiple-filter';
import Period from './Filters/period';
import SingleFilter from './Filters/single-filter';

const DropdownFilters = ({ filtersBy, updateFilterValue, filter = {} }) => {
  const { categoryId } = useParams();
  const [filters, setFilters] = useState(filter);

  useEffect(() => {
    if (updateFilterValue) {
      updateFilterValue(filters);
    }
  }, [filters]);

  useEffect(() => {
    setFilters({});
  }, [categoryId]);

  const updateFilters = (key, value) => {
    if (value === undefined) {
      const filtersKeys = Object.keys(filters);
      if (filtersKeys.includes(key)) {
        delete filters[key];
        setFilters({ ...filters });
      } else {
        setFilters({ ...filters, [key]: [] });
      }
      return;
    }

    const hasValueInFilter = filters[key]?.includes(value);

    if (hasValueInFilter) {
      const result = filters[key].filter(item => item !== value);
      setFilters({ ...filters, [key]: result });
    } else {
      setFilters({ ...filters, [key]: [...(filters[key] || []), value] });
    }
  };

  const getDropdownItem = (key, label) => ({
    key: key || label,
    label: key ? (
      <div
        key={key || label}
        style={{ display: 'flex', width: '100%', alignItems: 'center', cursor: 'pointer', margin: '5px 0' }}
        role="presentation"
      >
        <Checkbox checked={filters[key]} />
        <span style={{ marginLeft: 10 }}>{label}</span>
      </div>
    ) : (
      <span key={label} style={{ fontWeight: 700, color: '#000' }}>
        {label}
      </span>
    ),
    disabled: !key,
  });

  const generalFilters = () => filtersBy.map(item => getDropdownItem(item.id, item.name));

  const activeFilters = useMemo(() => {
    const applyFilters = Object.keys(filters);

    return applyFilters.map((f, index) => {
      const element = filtersBy.filter(el => el.id === f)[0];
      if (element.period) {
        return (
          <Period
            key={index}
            field={element}
            values={filters[element.id]}
            onChange={(key, value) => updateFilters(key, value)}
          />
        );
      }
      if (element.children) {
        return (
          <MultipleFilter
            key={index}
            field={element}
            options={element.children}
            values={filters[element.id]}
            onChange={(key, value) => updateFilters(key, value)}
          />
        );
      }

      return (
        <SingleFilter
          key={index}
          field={element}
          values={filters[element.id]}
          onChange={(key, value) => updateFilters(key, value)}
        />
      );
    });
  }, [filters]);

  return (
    <>
      {activeFilters}
      <Dropdown
        menu={{
          items: generalFilters(),
          onClick: e => {
            updateFilters(e.key);
          },
        }}
        trigger={['click']}
      >
        <span style={{ color: 'rgba(178, 178, 199, 1)', cursor: 'pointer', marginTop: activeFilters.length && 5 }}>
          + Add filter
        </span>
      </Dropdown>
    </>
  );
};

export default DropdownFilters;
