import { notification } from 'antd';

const useNotification = () => {
  const openNotification = ({ type = 'open', placement = 'topRight', message, description }) => {
    notification[type]({
      message,
      description,
      placement,
    });
  };

  return { openNotification };
};

export default useNotification;
