import { useState, useEffect, useRef } from 'react';

const TranculatedText = ({ text = '', parrentBoxWidth, className }) => {
  const textRef = useRef(null);
  const [modifyText, setModifyText] = useState(text);
  const [defaultElementWidth, setDefaultElementWidth] = useState();

  useEffect(() => {
    const element = textRef.current;
    setDefaultElementWidth(element.clientWidth);
  }, []);

  useEffect(() => {
    const element = textRef.current;
    const textBoxWidth = parrentBoxWidth - 81 - 32;
    const totalElementWidth = defaultElementWidth || element.clientWidth;
    const isHiddenWidth = textBoxWidth - totalElementWidth < 0;
    if (textBoxWidth && totalElementWidth && (isHiddenWidth || element.clientWidth < defaultElementWidth)) {
      const textLengthMustBe = textBoxWidth / 8 - 4;
      const midpoint = Math.floor(textLengthMustBe / 2);
      const firstPart = text.substring(0, midpoint);
      const secondPart = text.substring(text.length - midpoint);
      setModifyText(`${firstPart}...${secondPart}`);
      return;
    }

    setModifyText(text);
  }, [parrentBoxWidth]);

  return (
    <div style={{ overflow: 'hidden' }}>
      <div className={className} style={{ whiteSpace: 'nowrap' }} ref={textRef}>
        {modifyText}
      </div>
    </div>
  );
};

export default TranculatedText;
