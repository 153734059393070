import axios from 'axios';

const getInfo = () => axios.get('api/v1/dashboard');

const getOpenedAssets = () => axios.get('api/v1/openedAssets');

const postOpenedAsset = assetId => axios.post('api/v1/openedAssets', { assetId });

const getRecentlyAddedAssets = params => axios.get('api/v1/assets/recentlyCreated', params);

const ApiDashboard = {
  getInfo,
  getOpenedAssets,
  postOpenedAsset,
  getRecentlyAddedAssets,
};

export default ApiDashboard;
