import { useMemo } from 'react';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Avatar, Form, Input, Select } from 'antd';
import { useSelector } from 'react-redux';
import selectItemNormalizer from '../../../../../../common/helpers/select-item-normalizer';
import { selectIsAdmin } from '../../../../../../store/slices/user';

const AssetInfoFormGeneral = ({
  t,
  form,
  fileTypes,
  users,
  onSelectChange,
  updateForm,
  fileTypeIsEmpty,
  categoryIdIsEmpty,
  categories,
  categoryId,
}) => {
  const isAdmin = useSelector(selectIsAdmin);
  const authorsOptions = () =>
    users.map(item => (
      <Select.Option name={item.name} value={item.id} key={item.id} style={{ display: 'flex', alignItems: 'center' }}>
        {item.avatar && <Avatar src={item.avatar} size={20} style={{ marginRight: 10 }} />}
        {item?.firstName} {item?.lastName}
      </Select.Option>
    ));

  const getCategoriesByAlphabetical = useMemo(
    () =>
      [...categories].sort((a, b) => {
        if (a.name.toLowerCase() < b.name.toLowerCase()) {
          return -1;
        }
        if (a.name.toLowerCase() > b.name.toLowerCase()) {
          return 1;
        }
        return 0;
      }),
    [categories]
  );

  return (
    <>
      <Form.Item name="categoryId">
        <div>
          <p className="assets-form--required" style={{ marginRight: 10 }}>
            {t('assets.info.form.category')}
          </p>
          <div style={{ marginLeft: -11, minWidth: 150, display: 'flex' }}>
            {categoryIdIsEmpty && <ExclamationCircleOutlined style={{ color: 'red', marginRight: 10, fontSize: 20 }} />}
            <Select
              defaultValue={form.categoryId || categoryId}
              bordered={false}
              options={selectItemNormalizer(getCategoriesByAlphabetical)}
              onChange={id => onSelectChange('categoryId', id)}
              placeholder="Select category"
              dropdownStyle={{ minWidth: 200 }}
            />
          </div>
        </div>
      </Form.Item>
      <Form.Item name="version">
        <div>
          <p>{t('assets.info.form.version')}</p>
          <div style={{ marginLeft: -11 }}>
            <Input
              bordered={false}
              placeholder="Document version"
              defaultValue={form?.version}
              style={{ width: 350 }}
              onChange={({ target }) => updateForm({ version: target.value })}
            />
          </div>
        </div>
      </Form.Item>
      <Form.Item name="link">
        <div>
          <p>{t('assets.info.form.source')}</p>
          <div style={{ marginLeft: -11 }}>
            <Input
              bordered={false}
              style={{ width: 350 }}
              placeholder="Enter link to source document"
              defaultValue={form?.link}
              onChange={({ target }) => updateForm({ link: target.value })}
            />
          </div>
        </div>
      </Form.Item>
      <Form.Item name="fileType">
        <div>
          <p className="assets-form--required" style={{ marginRight: 10 }}>
            {t('assets.info.form.type')}
          </p>
          <div style={{ marginLeft: -11, minWidth: 150, display: 'flex' }}>
            {fileTypeIsEmpty && <ExclamationCircleOutlined style={{ color: 'red', marginRight: 10, fontSize: 20 }} />}
            <Select
              defaultValue={selectItemNormalizer(form.fileType)}
              bordered={false}
              options={selectItemNormalizer(fileTypes)}
              onChange={id => onSelectChange('fileTypeId', id)}
              placeholder="Select file type"
            />
          </div>
        </div>
      </Form.Item>
      <Form.Item name="author">
        <div>
          <p>{t('assets.info.form.author')}</p>
          {isAdmin ? (
            <div style={{ marginLeft: -11, minWidth: 200, display: 'flex' }}>
              <Select
                value={form.userId || form.author.id}
                bordered={false}
                onChange={id => onSelectChange('userId', id)}
                placeholder="Select author"
                disabled={!isAdmin}
              >
                {authorsOptions()}
              </Select>
            </div>
          ) : (
            <div>
              {form.author.avatar && <Avatar src={form.author.avatar} size={20} style={{ marginRight: 10 }} />}
              <span>
                {form.author.firstName} {form.author.lastName}
              </span>
            </div>
          )}
        </div>
      </Form.Item>
    </>
  );
};

export default AssetInfoFormGeneral;
