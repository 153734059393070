import { createSlice } from '@reduxjs/toolkit';
import ApiUsersManagement from '../../api/user-management';

export const usersManagementSlice = createSlice({
  name: 'usersManagement',
  initialState: {
    users: [],
    hasMore: false,
    isOpenProfile: false,
    user: null,
    isLoading: false,
  },
  reducers: {
    setUsers: (state, action) => ({
      ...state,
      users: action.payload,
      user: state.user ? action.payload.filter(u => u.id === state.user.id)[0] : null,
      hasMore: action.payload?.length && action.payload?.length === 30,
      isLoading: false,
    }),
    resetUsers: state => ({ ...state, users: [], hasMore: false, isLoading: false }),
    setIsOpenProfile: (state, action) => ({
      ...state,
      isOpenProfile: action.payload,
    }),
    setProfileUser: (state, action) => ({
      ...state,
      user: action.payload,
    }),
    setLoading: state => ({ ...state, isLoading: true }),
  },
});

export const { setUsers, resetUsers, setProfileUser, setIsOpenProfile, setLoading } = usersManagementSlice.actions;

export const fetchUsers = query => dispatch => {
  dispatch(setLoading());
  ApiUsersManagement.getUsers(query).then(r => {
    dispatch(setUsers(r.data.users));
  });
};

export const clearUsers = () => dispatch => {
  dispatch(resetUsers());
};

export const selectUsers = state => state.usersManagement.users;
export const selectHasMoreUsers = state => state.usersManagement.hasMore;
export const selectIsOpenProfile = state => state.usersManagement.isOpenProfile;
export const selectProfileUser = state => state.usersManagement.user;
export const selectUsersLoading = state => state.usersManagement.isLoading;

export default usersManagementSlice.reducer;
