import { Form } from 'antd';
import DropdownTagsSelect from '../../../../../../components/dropdown-tags-select/dropdown-tags-select';
import '../../../../styles.css';

const AssetInfoFormCompetitive = ({ t, form, intelligences, intelligenceTags, onSelectChange, onDropdownChanged }) => (
  <>
    <h3>{t('assets.info.form.competitive')}</h3>
    <Form.Item>
      <div>
        <p>{t('assets.info.form.products')}</p>
        <div style={{ maxWidth: 400, flexWrap: 'wrap', justifyContent: 'flex-start' }}>
          <DropdownTagsSelect
            options={intelligences}
            onCreate={tag => onSelectChange('intelligences', tag, false, true)}
            onChange={tag => onDropdownChanged('intelligences', tag)}
            defaultValue={form.intelligences}
            className="competitive-select"
          />
        </div>
      </div>
    </Form.Item>
    <Form.Item>
      <div>
        <p>{t('assets.info.form.tags')}</p>
        <div style={{ maxWidth: 400, flexWrap: 'wrap', justifyContent: 'flex-start' }}>
          <DropdownTagsSelect
            options={intelligenceTags}
            onCreate={tag => onSelectChange('intelligenceTags', tag, false, true)}
            onChange={tag => onDropdownChanged('intelligenceTags', tag)}
            defaultValue={form.intelligenceTags}
            className="competitive-select"
          />
        </div>
      </div>
    </Form.Item>
  </>
);

export default AssetInfoFormCompetitive;
