import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import ApiDashboard from '../../../api/dashboard';
import getDate from '../../../common/helpers/calendar/get-date';
import ResizableTable from '../../../components/resizable-table/resizable-table';
import { setCurrentAsset } from '../../../store/slices/assets';
import AssetCard from '../../assets/components/asset-card';

const LastOpened = ({ documents, isLoading, categories }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isCollapse, setCollapse] = useState(true);

  const setAsset = asset => {
    dispatch(setCurrentAsset(asset));
    ApiDashboard.postOpenedAsset(asset.id);
  };

  const getCategoryName = categoryId => categories.filter(c => c.id === categoryId)[0]?.name;

  const columns = useMemo(
    () => [
      {
        title: t('dashboard.table.last_opened'),
        dataIndex: 'name',
        render: (_, document) => <AssetCard data={document} selectAsset={setAsset} />,
        key: 'name',
        width: '50%',
      },
      {
        title: t('dashboard.table.opened'),
        dataIndex: 'opened',
        key: 'opened',
        render: (_, document) => <span>{getDate(document.updatedAt)}</span>,
      },
      {
        title: t('dashboard.table.category'),
        dataIndex: 'category',
        key: 'category',
        render: (_, document) => <span>{getCategoryName(document.categoryId) || 'N/A'}</span>,
      },
    ],
    [categories]
  );

  const toogleCollapse = () => {
    setCollapse(!isCollapse);
  };

  const tableClass = () => {
    let className = 'dashboard__last-opened__table-box';
    if (isCollapse) {
      className += ' dashboard__last-opened__table-box--collapse';
    }

    return className;
  };

  return (
    <div className="dashboard__last-opened">
      <div
        className={tableClass()}
        style={{ maxHeight: isCollapse ? 300 : (documents.length + 1) * 80 }}
        data-testid="last-opened"
      >
        <ResizableTable
          rowClassName="clickable"
          columns={columns}
          dataSource={documents}
          pagination={false}
          rowKey={doc => doc.id}
          loading={isLoading}
          onRow={data => ({
            onClick: () => setAsset(data),
          })}
          showSorterTooltip={false}
        />
      </div>
      {documents.length > 3 && (
        <span className="dashboard__last-opened__more-btn" onClick={toogleCollapse} role="presentation">
          {isCollapse ? `Show ${documents.length - 3} more` : 'Collapse'}
        </span>
      )}
    </div>
  );
};

export default LastOpened;
