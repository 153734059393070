import { Divider, Form, Input } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { useState } from 'react';
import ApiAuth from '../../../api/auth';
import ModalWrapper from '../../../components/modal/modal';
import ValidationChecklist from '../../../components/validation-checklist/validation-checklist';

const ChangePasswordForm = ({ t, isOpen, inProgress, close }) => {
  const [form] = useForm();
  const [errorFields, setErrorFields] = useState();
  const [errorMsg, setErrorMsg] = useState();
  const currentErrorsTexts = [
    t('login.validation_msg.not_contain_name_mail'),
    t('login.validation_msg.min_characters'),
    t('login.validation_msg.contain_num_symbol'),
  ];
  const validationRules = currentErrorsTexts.reduce(
    (acc, rule) => [
      ...acc,
      {
        text: rule,
        invalidColor: '#B2B2C7',
        validColorCheckbox: 'rgba(54, 179, 126, 1)',
        validColorText: 'rgba(21, 21, 21, 1)',
      },
    ],
    []
  );

  const submit = () => {
    if (errorFields?.length) return null;

    const { oldPassword, password } = form.getFieldsValue();

    return ApiAuth.updatePassword(oldPassword, password)
      .then(() => {
        close();
      })
      .catch(e => {
        if (e.response?.data?.message) {
          setErrorMsg(e.response.data.message);
        }
      });
  };

  const onChange = () => {
    form
      .validateFields()
      .then(() => {
        setErrorFields([]);
      })
      .catch(r => {
        const errorsList = r.errorFields?.reduce((acc, field) => [...acc, ...field.errors.map(e => e)], []);
        const list = errorsList ? [...errorsList] : [];
        setErrorFields([...list]);
      });
  };

  return (
    <ModalWrapper
      isOpen={isOpen}
      title={t('profile.change_password')}
      onOk={submit}
      onCancel={close}
      okText={t('login.save')}
      okButtonProps={{
        size: 'large',
      }}
      cancelButtonProps={{
        size: 'large',
        style: { backgroundColor: 'rgba(108, 108, 137, 0.08)' },
      }}
      onClose={close}
    >
      <Divider />
      <Form form={form}>
        <p>{t('login.old_password')}</p>
        <Form.Item
          name="oldPassword"
          rules={[
            {
              required: true,
              message: t('login.validation_msg.password'),
            },
            {
              type: 'string',
              min: 8,
              message: t('login.validation_msg.min_characters'),
            },
            {
              pattern: /(?=.*\d)(?=.*[a-z])(?=.*\W)/,
              message: t('login.validation_msg.contain_num_symbol'),
            },
          ]}
        >
          <Input.Password placeholder={t('login.old_password')} size="large" disabled={inProgress} />
        </Form.Item>
        <p>{t('login.password')}</p>
        <Form.Item
          name="password"
          data-testid="password"
          rules={[
            {
              required: true,
              message: t('login.validation_msg.password'),
            },
            {
              type: 'string',
              min: 8,
              message: t('login.validation_msg.min_characters'),
            },
            {
              pattern: /(?=.*\d)(?=.*[a-z])(?=.*\W)/,
              message: t('login.validation_msg.contain_num_symbol'),
            },
          ]}
        >
          <Input.Password placeholder={t('login.password')} size="large" disabled={inProgress} />
        </Form.Item>
        <p>{t('login.confirm_password')}</p>
        <Form.Item
          name="confirm"
          data-testid="confirm-password"
          dependencies={['password']}
          onChange={onChange}
          rules={[
            {
              required: true,
              message: t('login.validation_msg.password'),
            },
            {
              pattern: /(?=.*\d)(?=.*[a-z])(?=.*\W)/,
              message: t('login.validation_msg.contain_num_symbol'),
            },
            {
              type: 'string',
              min: 8,
              message: t('login.validation_msg.min_characters'),
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error(t('login.validation_msg.match_password')));
              },
            }),
          ]}
        >
          <Input.Password placeholder={t('login.confirm_password')} size="large" disabled={inProgress} />
        </Form.Item>
        {errorMsg && (
          <Form.Item>
            <p className="error-msg" data-testid="error">
              {errorMsg}
            </p>
          </Form.Item>
        )}
        <Form.Item>
          <ValidationChecklist
            validationRules={validationRules}
            errorFields={errorFields}
            touched={form.isFieldsTouched(['password', 'confirm'])}
            varificationValue={form.getFieldValue('confirm')}
          />
        </Form.Item>
      </Form>
      <Divider />
    </ModalWrapper>
  );
};

export default ChangePasswordForm;
