import { useMemo } from 'react';
import NotificationAssetCard from './card';

const NotificationsList = ({ items, selectAsset, categories = [], t }) => {
  const getCategoryById = id => categories.filter(category => category.id === id)[0];

  const list = useMemo(
    () => (
      <div className="notifications-list-box">
        {items.map(item => (
          <div key={item.id}>
            <NotificationAssetCard
              id={item.id}
              asset={item.Asset}
              eventType={item.name}
              viewed={item.viewed}
              selectAsset={selectAsset}
              category={getCategoryById(item.Asset.categoryId)?.name}
              changedAuthor={item.updatedByUser?.fullName}
              t={t}
            />
          </div>
        ))}
      </div>
    ),

    [items]
  );

  return list;
};

export default NotificationsList;
