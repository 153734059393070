import { Button, Form, Input, Select } from 'antd';
import getDate from '../../../common/helpers/calendar/get-date';
import getDateByDiff from '../../../common/helpers/calendar/get-date-by-diff';
import UploadAvatar from '../../../components/upload-avatar/upload-avatar';

const ProfileEditForm = ({
  t,
  user,
  open,
  roleOptions,
  isCurrentUser,
  showToogleStatusButton,
  isDisabledUser,
  toogleUserStatus,
  onRoleChange,
  userStatusRender,
  onAvatarChange,
  isAdmin,
  openUserDeleteModal,
}) => (
  <>
    <Form.Item name="avatar" valuePropName="fileList">
      <UploadAvatar onChange={onAvatarChange} value={user.avatar} />
    </Form.Item>
    <Form.Item name="role">
      <div>
        <p>{t('profile.form.role')}</p>
        <div>
          <Select
            className="form-input-elements"
            bordered={false}
            options={roleOptions}
            disabled={isCurrentUser() || !isAdmin}
            defaultValue={roleOptions.filter(role => role.value === user.role)[0]}
            onSelect={onRoleChange}
          />
        </div>
      </div>
    </Form.Item>
    <Form.Item>
      <div>
        <p>{t('profile.form.status')}</p>
        <div>
          <div>{userStatusRender(user.status)}</div>
        </div>
      </div>
    </Form.Item>
    <Form.Item name="firstName">
      <div>
        <p>{t('profile.form.first_name')}</p>
        <div>
          <Input className="form-input-elements" bordered={false} defaultValue={user.firstName} />
        </div>
      </div>
    </Form.Item>
    <Form.Item name="lastName">
      <div>
        <p>{t('profile.form.last_name')}</p>
        <div>
          <Input className="form-input-elements" bordered={false} defaultValue={user.lastName} />
        </div>
      </div>
    </Form.Item>
    <Form.Item name="email">
      <div className="profile-form-disabled-fields">
        <p>{t('profile.form.email')}</p>
        <span>{user.email}</span>
      </div>
    </Form.Item>
    <Form.Item>
      <div style={{ cursor: 'pointer' }}>
        <p>{t('profile.form.password')}</p>
        <span onClick={open} role="presentation">
          • • • • • • • • • • •
        </span>
      </div>
    </Form.Item>
    <Form.Item>
      <div className="profile-form-disabled-fields">
        <p>{t('profile.form.invited')}</p>
        <div>
          <span>{getDate(user.invitedAt)}</span>
        </div>
      </div>
    </Form.Item>
    <Form.Item>
      <div className="profile-form-disabled-fields">
        <p>{t('profile.form.last_active')}</p>
        <div>
          <span>{getDateByDiff(user.lastActivity)}</span>
        </div>
      </div>
    </Form.Item>
    {!isCurrentUser() && isAdmin && showToogleStatusButton() && (
      <div>
        <Button
          key="toogleUser"
          type="secondary"
          style={{ background: 'rgba(108, 108, 137, 0.08)', height: 40 }}
          onClick={toogleUserStatus}
        >
          {t(`profile.form.${isDisabledUser() ? 'activate_user' : 'deactivate_user'}`)}
        </Button>

        {isDisabledUser() && (
          <Button key="delete" type="primary" danger ghost style={{ height: 40 }} onClick={openUserDeleteModal}>
            {t('profile.form.delete')}
          </Button>
        )}
      </div>
    )}
  </>
);

export default ProfileEditForm;
