const toQueryString = (path, query = {}) => {
  let link = path;
  const queryKeys = Object.keys(query);
  if (queryKeys.length) {
    for (let i = 0; i < queryKeys.length; i = i + 1) {
      if (query[queryKeys[i]]) {
        link = Array.isArray(query[queryKeys[i]])
          ? query[queryKeys[i]].reduce((acc, item) => `${acc}${queryKeys[i]}[]=${item}&`, `${link}`)
          : `${link}${queryKeys[i]}=${query[queryKeys[i]]}&`;
      }
    }
  }

  return link;
};

export default toQueryString;
