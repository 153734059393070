import emptySearch from '../../assets/images/empty-search.svg';
import './styles.css';

const Placeholder = ({ title, description }) => (
  <div className="assets-placeholder">
    <div className="assets-placeholder__wrapper">
      <div className="assets-placeholder__content">
        <img className="assets-placeholder__icon" src={emptySearch} alt={title} />
        {title && <h4>{title}</h4>}
        {description && <p>{description}</p>}
      </div>
    </div>
  </div>
);

export default Placeholder;
