import { useEffect, useRef, useState } from 'react';
import '../styles.css';
import fileIcons from '../../../common/file-icons';
import TranculatedText from '../../../components/tranculated-text/tranculated-text';

const AssetCard = ({ data, selectAsset }) => {
  const cellContentRef = useRef();
  const [cellWidth, setCellWidth] = useState();

  useEffect(() => {
    const cellContent = cellContentRef.current;
    setCellWidth(cellContent.clientWidth);
  }, [cellContentRef.current?.clientWidth]);

  return (
    <div ref={cellContentRef} className="asset-card" onClick={() => selectAsset(data)} role="presentation">
      <img src={fileIcons(data.fileTypeId)} alt="asset logo" className="asset-card-logo" />
      <div className="asset-card__info">
        <TranculatedText text={data.name} parrentBoxWidth={cellWidth} className="h4" />
        <p>{data.type}</p>
      </div>
    </div>
  );
};

export default AssetCard;
