import { Spin, TimePicker } from 'antd';
import dayjs from 'dayjs';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ApiAdministration from '../../../api/administration';
import toValidTimeFormat from '../../../common/helpers/time/to-valid-time-format';
import DropdownTagsSelect from '../../../components/dropdown-tags-select/dropdown-tags-select';
import Header from '../../../components/header/header';
import useNotification from '../../../components/notification/notification';

const roles = [
  { label: 'Admin', id: 'admin' },
  { label: 'User', id: 'user' },
];

const NotificationSettings = () => {
  const { t } = useTranslation();
  const { openNotification } = useNotification();
  const [notificationTime, setNotificationTime] = useState();
  const [recipients, setRecipients] = useState();
  const [defaultValues, setDefaultValues] = useState({});
  const [wasChanged, setChanged] = useState(false);

  const updateTime = time => {
    const { $H, $m } = time;
    setChanged(true);
    setNotificationTime(toValidTimeFormat($H, $m));
  };

  const updateRecipients = role => {
    if (JSON.stringify(role) !== JSON.stringify(roles.filter(r => (recipients || []).includes(r.id)))) {
      setChanged(true);
    }
    setRecipients(role.map(r => r.id));
  };

  const save = () => {
    const [emailNotificationHour, emailNotificationMinute] = (notificationTime || '').split(':');
    const emailExpiredRole = recipients;

    ApiAdministration.updateSettings({ emailNotificationHour, emailNotificationMinute, emailExpiredRole })
      .then(() => {
        openNotification({
          type: 'success',
          message: <p>Notification time was updated</p>,
        });
      })
      .catch(() => {
        openNotification({
          type: 'error',
          message: <p>Notification time update error!</p>,
        });
      })
      .finally(() => {
        setChanged(false);
      });
  };

  const cancel = () => {
    setRecipients(defaultValues.recipients);
    setNotificationTime(defaultValues.notificationTime);
  };

  useEffect(() => {
    ApiAdministration.getSettings().then(r => {
      const { emailNotificationHour, emailNotificationMinute, emailExpiredRole } = r.data.settings;
      const formatedTime = toValidTimeFormat(emailNotificationHour, emailNotificationMinute);
      setNotificationTime(formatedTime);
      setRecipients(emailExpiredRole || []);
      setDefaultValues({ notificationTime: formatedTime, recipients: emailExpiredRole });
    });
  }, []);

  const recipientsDefaultValue = useMemo(() => roles.filter(r => (recipients || []).includes(r.id)), [recipients]);

  return (
    <div>
      <Header
        className="administration__header"
        title={t('administration.notifications.title')}
        apply={wasChanged && { label: 'Save', onClick: save }}
        cancel={wasChanged && { label: 'Cancel', onClick: cancel }}
      />
      <div className="administration__notification__box">
        <div className="administration__notification__item">
          <p>{t('administration.notifications.current_time')}</p>
          {notificationTime ? (
            <div style={{ marginLeft: '-8px', cursor: 'pointer' }}>
              <TimePicker
                size="small"
                value={dayjs(notificationTime, 'HH:mm')}
                onChange={updateTime}
                format="HH:mm"
                bordered={false}
              />
            </div>
          ) : (
            <Spin />
          )}
        </div>
        <div className="administration__notification__item">
          <p>{t('administration.notifications.expired')}</p>
          <div style={{ maxWidth: 400, flexWrap: 'wrap', justifyContent: 'flex-start' }}>
            {recipients && (
              <DropdownTagsSelect
                options={roles}
                onChange={updateRecipients}
                defaultValue={recipientsDefaultValue}
                placeholder="+ Add"
                className="competitive-select"
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotificationSettings;
