import { Badge } from 'antd';
import fileIcons from '../../../common/file-icons';
import getDateByDiff from '../../../common/helpers/calendar/get-date-by-diff';

const NotificationAssetCard = ({ id, asset, eventType, selectAsset, viewed, t, category, changedAuthor }) => (
  <div className="notification-asset-card" onClick={() => selectAsset(asset, id)} role="presentation">
    <div className="notification-asset-card__icon">
      {!viewed && <Badge status="processing" className="notification-asset-card__badge" />}
      <img src={fileIcons(asset.fileTypeId)} alt="asset logo" className="asset-card-logo" />
    </div>
    <div className="notification-asset-card__info">
      <p className="notification-asset-name">{asset.name}</p>
      <p className="notification-asset-event-text">
        {t(`notifications.asset_actions.${eventType}`, {
          ago: getDateByDiff(asset.updatedAt),
          changed_author: changedAuthor,
        })}
        <span style={{ fontWeight: 600 }}>{category}</span>
      </p>
    </div>
  </div>
);

export default NotificationAssetCard;
