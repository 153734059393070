import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import axios from 'axios';
import './index.css';
import { ConfigProvider } from 'antd';
import { I18nextProvider, initReactI18next } from 'react-i18next';
import i18next from 'i18next';
import store from './store';
import customDesign from './common/custom-design';
import en from './common/locales/en.json';
import PrivateRoutes from './common/utils/private-routes';
import App from './app';
import Login from './views/login/login';
import ResetPass from './views/login/reset-password';
import NewPass from './views/login/new-password';
import Assets from './views/assets/assets';
import UserManagament from './views/user-management/user-managament';
import AuditTrail from './views/audit-trail/audit-trail';
import AdminRoutes from './common/utils/admin-routes';
import Dashboard from './views/dashboard/dashboard';
import Administration from './views/administration/administration';

axios.defaults.baseURL = process.env.REACT_APP_DEV_URL || document.location.origin;

i18next.use(initReactI18next).init({
  lng: 'en',
  resources: {
    en: {
      translation: en,
    },
  },
  interpolation: { escapeValue: false },
});

const root = ReactDOM.createRoot(document.getElementById('root'));

setTimeout(() => {
  document.title = window.appName;
}, 1000);

root.render(
  <Provider store={store}>
    <I18nextProvider i18n={i18next}>
      <ConfigProvider theme={customDesign}>
        <Router>
          <Routes>
            <Route element={<Login />} path="/login" exact />
            <Route element={<ResetPass />} path="login/reset" />
            <Route element={<NewPass />} path="login/new-password" />
            <Route element={<PrivateRoutes />}>
              <Route element={<App />} path="/" exact>
                <Route element={<Dashboard />} path="/" />
                <Route element={<Assets />} path="/assets" exact />
                <Route element={<Assets />} path="/assets/asset/:assetId" />
                <Route element={<Assets />} path="/assets/search" />
                <Route element={<Assets />} path="/assets/category/:categoryId" />
                <Route element={<Assets />} path="/assets/category/:categoryId/asset/:assetId" />
                <Route element={<Assets />} path="/assets/expired" />
                <Route element={<Assets />} path="/assets/about-to-expire" />
                <Route element={<Assets />} path="/assets/last-created" />
                <Route element={<AdminRoutes />}>
                  <Route element={<UserManagament />} path="/user-management" />
                  <Route element={<AuditTrail />} path="/audit-trail" />
                  <Route element={<Administration />} path="/administration" />
                </Route>
              </Route>
            </Route>
          </Routes>
        </Router>
      </ConfigProvider>
    </I18nextProvider>
  </Provider>
);
