import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import ApiDashboard from '../../api/dashboard';
import Header from '../../components/header/header';
import { setCurrentAsset } from '../../store/slices/assets';
import { selectCategories } from '../../store/slices/categories';
import { selectUser } from '../../store/slices/user';
import AssetInfo from '../assets/components/asset-info/asset-info';
import useAssetConfigParams from '../assets/components/hooks/assetConfigParams';
import Breakdowns from './components/breakdowns';
import LastAdded from './components/last-added';
import LastOpened from './components/last-opened';
import Summary from './components/summary';
import './styles.css';
import LoadingPage from './components/loading-page';

const Dashboard = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [info, setInfo] = useState({});
  const [lastOpened, setLastOpened] = useState([]);
  const [lastAdded, setLastAdded] = useState([]);
  const [isLoadingLastOpened, setLoadingLastOpened] = useState(false);
  const [isLoadingRecentAdded, setLoadingResentAdded] = useState(false);
  const [isLoadingSummaryInfo, setLoadingSummaryInfo] = useState(false);
  const categories = useSelector(selectCategories);
  const { getConfigParams, setConfigParams, refresh } = useAssetConfigParams();
  const currentUser = useSelector(selectUser);
  const [hasMore, setHasMore] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [isDashboardReady, setDashboardReady] = useState(false);
  const [componentsReady, setComponentsReady] = useState(false);
  const [isPreparing, setPreparing] = useState(true);

  const fetchLastOpened = () => {
    setLoadingLastOpened(true);
    ApiDashboard.getOpenedAssets().then(r => {
      const assets = r.data.assets.filter(a => a);
      setLastOpened(assets.filter((asset, index, self) => index === self.findIndex(a => a.id === asset.id)));
    });
  };

  const fetchRecetlyAdded = (page, isFirstLoading) => {
    if (!isFirstLoading && isLoadingRecentAdded) {
      return;
    }

    setLoadingResentAdded(true);

    ApiDashboard.getRecentlyAddedAssets({ page }).then(r => {
      const assetsList = r.data.assets;
      setLastAdded(assetsList);
      if (!assetsList.length) {
        setHasMore(false);
      }
    });

    setCurrentPage(currentPage + 1);
  };

  const fetchSummaryInfo = () => {
    setLoadingSummaryInfo(true);

    ApiDashboard.getInfo().then(r => {
      r.data.categories = categories
        .map(category => ({
          ...category,
          ...r.data.categories.filter(c => c.id === category.id)[0],
        }))
        .sort((a, b) => {
          if (a.name.toLowerCase() < b.name.toLowerCase()) {
            return -1;
          }
          if (a.name.toLowerCase() > b.name.toLowerCase()) {
            return 1;
          }
          return 0;
        });
      setInfo(r.data);
    });
  };

  const getData = () => {
    fetchSummaryInfo();
    fetchRecetlyAdded(null, true);
    fetchLastOpened();
  };

  const addAsset = () => {
    dispatch(setCurrentAsset({ author: currentUser }));
  };

  useEffect(() => {
    getData();
  }, [categories]);

  useEffect(() => {
    if (isLoadingLastOpened) {
      setLoadingLastOpened(false);
    }
  }, [lastOpened]);

  useEffect(() => {
    if (isLoadingRecentAdded) {
      setLoadingResentAdded(false);
    }
  }, [lastAdded]);

  useEffect(() => {
    if (isLoadingSummaryInfo) {
      setLoadingSummaryInfo(false);
    }
  }, [info]);

  useEffect(() => {
    let dashboardReadyDelay;

    if (isPreparing) {
      setPreparing(false);
    }

    if (!isPreparing && !isLoadingRecentAdded && !isLoadingLastOpened) {
      setComponentsReady(true);
      dashboardReadyDelay = setTimeout(() => {
        setDashboardReady(true);
      }, 1000);
    }

    return () => clearTimeout(dashboardReadyDelay);
  }, [!isLoadingRecentAdded && !isLoadingLastOpened]);

  return (
    <div className="dashboard">
      <InfiniteScroll dataLength={lastAdded.length} next={fetchRecetlyAdded} hasMore={hasMore} scrollThreshold={0.9}>
        <Header
          className="dashboard__header"
          title={t('dashboard.title')}
          create={{
            label: t('assets.btn.add_asset'),
            onClick: addAsset,
          }}
        />

        <Summary total={info.total} expired={info.expired} aboutToExpire={info.aboutToExpire} />
        <Breakdowns total={info.total} categories={info.categories} />
        <LastOpened documents={lastOpened} isLoading={isLoadingLastOpened} categories={categories} />
        <LastAdded
          documents={lastAdded}
          isLoading={isLoadingRecentAdded}
          categories={categories}
          fetchAssets={fetchRecetlyAdded}
          hasMore={hasMore}
        />
        <AssetInfo
          categories={categories}
          getConfigParams={getConfigParams}
          setConfigParams={setConfigParams}
          refresh={refresh}
          fetchAssets={getData}
          dashboard
        />

        {!isDashboardReady && <LoadingPage isLoading={!componentsReady} />}
      </InfiniteScroll>
    </div>
  );
};

export default Dashboard;
