import { createSlice } from '@reduxjs/toolkit';
import ApiAssets from '../../api/assets';

export const domainsSlice = createSlice({
  name: 'domains',
  initialState: {
    domains: [],
  },
  reducers: {
    setDomains: (state, action) => ({ ...state, domains: [...action.payload] }),
  },
});

export const { setDomains } = domainsSlice.actions;

export const fetchAllDomains = () => dispatch => {
  ApiAssets.getAllDomains().then(res => {
    dispatch(setDomains(res.data.domains));
  });
};

export const selectDomains = state => state.domains.domains;

export default domainsSlice.reducer;
