import { useState } from 'react';
import { Form } from 'antd';
import './styles.css';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import ResetPassView from './reset-password-view';
import ApiAuth from '../../api/auth';
import useNotification from '../../components/notification/notification';

function ResetPass() {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const { openNotification } = useNotification();
  const [inProgress] = useState(false);
  const navigation = useNavigate();

  const resetPassword = () => {
    const { email } = form.getFieldsValue();
    ApiAuth.resetPassword(email)
      .then(() => {
        localStorage.removeItem('user_token');
        openNotification({
          type: 'success',
          message: <p>{t('login.reset_success', { email })}</p>,
        });
        navigation('/login');
      })
      .catch(() => {
        openNotification({
          type: 'error',
          message: <p>{t('login.errors.no_email')}</p>,
        });
      });
  };

  return <ResetPassView form={form} t={t} inProgress={inProgress} resetPassword={resetPassword} />;
}

export default ResetPass;
