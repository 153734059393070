import { createSlice } from '@reduxjs/toolkit';
import ApiAssets from '../../api/assets';

export const categoriesSlice = createSlice({
  name: 'categories',
  initialState: {
    categories: [],
  },
  reducers: {
    setCategories: (state, action) => ({ ...state, categories: [...action.payload] }),
  },
});

export const { setCategories } = categoriesSlice.actions;

export const fetchAllCategories = () => dispatch => {
  ApiAssets.getAllCategories().then(res => {
    dispatch(setCategories(res.data.categories));
  });
};

export const selectCategories = state => state.categories.categories;

export default categoriesSlice.reducer;
