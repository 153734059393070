import { Avatar, Button, Form } from 'antd';
import getDate from '../../../common/helpers/calendar/get-date';
import getDateByDiff from '../../../common/helpers/calendar/get-date-by-diff';

const ProfileViewForm = ({
  t,
  user,
  userStatusRender,
  userRoleRender,
  reInvite,
  showReInviteButton,
  isCurrentUser,
  isAdmin,
  openUserDeleteModal,
}) => (
  <>
    {user.avatar && (
      <Form.Item>
        <Avatar size={120} src={user.avatar} />
      </Form.Item>
    )}
    <Form.Item>
      <div>
        <p>{t('profile.form.role')}</p>
        <div>{userRoleRender(user.role)}</div>
      </div>
    </Form.Item>
    <Form.Item>
      <div>
        <p>{t('profile.form.status')}</p>
        <div>{userStatusRender(user.status)}</div>
      </div>
    </Form.Item>
    <Form.Item>
      <div>
        <p>{t('profile.form.first_name')}</p>
        <div>
          <span>{user.firstName}</span>
        </div>
      </div>
    </Form.Item>
    <Form.Item>
      <div>
        <p>{t('profile.form.last_name')}</p>
        <div>
          <span>{user.lastName}</span>
        </div>
      </div>
    </Form.Item>
    <Form.Item>
      <div>
        <p>{t('profile.form.email')}</p>
        <div>
          <span>{user.email}</span>
        </div>
      </div>
    </Form.Item>
    <Form.Item>
      <div>
        <p>{t('profile.form.password')}</p>
        <span>• • • • • • • • • • •</span>
      </div>
    </Form.Item>
    <Form.Item>
      <div className="profile-form-disabled-fields">
        <p>{t('profile.form.invited')}</p>
        <div>
          <span>{getDate(user.invitedAt)}</span>
        </div>
      </div>
    </Form.Item>
    <Form.Item>
      <div className="profile-form-disabled-fields">
        <p>{t('profile.form.last_active')}</p>
        <div>
          <span>{getDateByDiff(user.lastActivity)}</span>
        </div>
      </div>
    </Form.Item>
    {!isCurrentUser() && isAdmin && showReInviteButton() && (
      <div>
        <Button key="reinvite" type="primary" style={{ height: 40 }} onClick={reInvite}>
          {t('profile.form.reinvite')}
        </Button>
        <Button key="delete" type="primary" danger ghost style={{ height: 40 }} onClick={openUserDeleteModal}>
          {t('profile.form.delete')}
        </Button>
      </div>
    )}
  </>
);

export default ProfileViewForm;
