import { createSlice } from '@reduxjs/toolkit';
import ApiAssets from '../../api/assets';

export const assetsSlice = createSlice({
  name: 'assets',
  initialState: {
    assets: [],
    hasMoreAssets: false,
    currentAsset: {},
  },
  reducers: {
    setAssets: (state, action) => ({
      ...state,
      assets: [...state.assets, ...action.payload],
      hasMoreAssets: (action.payload?.length && action.payload?.length === 30) || false,
    }),
    clearAssets: state => ({ ...state, assets: [], hasMoreAssets: false }),
    setCurrentAsset: (state, action) => ({ ...state, currentAsset: { ...action.payload } }),
    clearCurrentAsset: state => ({ ...state, currentAsset: {} }),
  },
});

export const { setAssets, clearAssets, setCurrentAsset, clearCurrentAsset } = assetsSlice.actions;

export const fetchAllAssets = query => dispatch => {
  ApiAssets.getAllDocuments(query).then(res => {
    dispatch(setAssets(res.data.assets));
  });
};

export const fetchAssetsByCategory = (categoryId, page, query) => dispatch => {
  ApiAssets.getAssetsByCategory(categoryId, page, query).then(res => {
    dispatch(setAssets(res.data.assets));
  });
};

export const selectAssets = state => state.assets.assets;
export const selectCurrentAsset = state => state.assets.currentAsset;
export const selectHasMoreAssets = state => state.assets.hasMoreAssets;

export default assetsSlice.reducer;
