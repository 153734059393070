import { useCallback } from 'react';
import DraggableItem from './draggable-item';

const ListComponent = ({ listItems, moveItem, updateItemName, remove }) => {
  const itemClassName = index => {
    if (!index) {
      return 'administration__categories__item--first';
    }

    if (index + 1 === listItems.length) {
      return 'administration__categories__item--last';
    }

    return 'administration__categories__item';
  };

  const renderCategory = useCallback(
    (item, index) => (
      <DraggableItem
        key={item.name + item.createdAt}
        index={index}
        id={item.id}
        text={item.name}
        className={itemClassName(index)}
        style={{ fontWeight: item.domain && 600, cursor: item.domain ? 'default' : 'pointer' }}
        canDrag={!item.domain}
        moveItem={moveItem}
        updateItemName={updateItemName}
        remove={remove}
      />
    ),
    [listItems]
  );

  return <div className="administration__categories__box">{listItems.map((i, idx) => renderCategory(i, idx))}</div>;
};

export default ListComponent;
