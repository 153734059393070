import { Avatar } from 'antd';

const UserCard = ({ user }) => {
  const { avatar, firstName, lastName } = user;

  return (
    <div className="user-management__table__name">
      {avatar && <Avatar src={avatar} style={{ marginRight: 10 }} />}
      <span>{`${firstName} ${lastName}`}</span>
    </div>
  );
};
export default UserCard;
