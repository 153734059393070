import { useForm } from 'antd/es/form/Form';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import ProfileView from './profile-view';
import ApiUser from '../../api/user';
import {
  fetchUsers,
  setIsOpenProfile,
  setProfileUser,
  selectIsOpenProfile,
  selectProfileUser,
} from '../../store/slices/user_management';
import { selectIsAdmin, selectUser, setUser } from '../../store/slices/user';
import ApiUsersManagement from '../../api/user-management';
import useNotification from '../../components/notification/notification';

const Profile = () => {
  const user = useSelector(selectProfileUser);
  const currentUser = useSelector(selectUser);
  const isAdmin = useSelector(selectIsAdmin);
  const isOpenProfile = useSelector(selectIsOpenProfile);
  const [form] = useForm();
  const [isEdit, setEdit] = useState(false);
  const [isOpenPassword, setOpenPassword] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isDataLoaded = () => !Object.keys(user).length;
  const { hash } = useLocation();
  const navigation = useNavigate();
  const [userRole, setUserRole] = useState();
  const [newUserAvatar, setNewUserAvatar] = useState();
  const { openNotification } = useNotification();
  const [isOpenDeleteModal, setOpenDeleteModal] = useState(false);
  const [isFieldsInit, setIsFieldsInit] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);

  const roleOptions = [
    {
      label: 'Administator',
      value: 'admin',
    },
    {
      label: 'User',
      value: 'user',
    },
  ];

  const fetchUsersList = () => {
    let status;
    switch (hash) {
      case '#disabled':
        status = 'inactive';
        break;
      case '#pending':
        status = ['invited', 'inviteError'];
        break;
      default:
        break;
    }

    dispatch(fetchUsers(status));
  };

  const updateUser = () => {
    const fields = form.getFieldsValue();

    const params = {};

    if (userRole) {
      fields.role = userRole;
    }

    if (newUserAvatar) {
      fields.avatar = newUserAvatar;
    }

    Object.keys(fields).forEach(key => {
      if (fields[key]) {
        params[key] = fields[key];
      }
    });

    ApiUser.update(user.id, params)
      .then(r => {
        setEdit(false);
        dispatch(setProfileUser(r.data.user));
        fetchUsersList();
        if (user.id === currentUser.id) {
          dispatch(setUser({ ...currentUser, ...params }));
        }
      })
      .then(() => {
        openNotification({
          type: 'success',
          message: <p>{t('profile.notification.update.success')}</p>,
        });
      })
      .catch(() => {
        openNotification({
          type: 'error',
          message: <p>{t('profile.notification.update.error')}</p>,
        });
      });
  };

  const reInvite = () => {
    const { id, email } = user;
    ApiUsersManagement.resendInvite(id)
      .then(() => {
        openNotification({
          type: 'success',
          message: <p>{t('profile.notification.reinvite.success', { email })}</p>,
        });
      })
      .catch(() => {
        openNotification({
          type: 'error',
          message: <p>{t('profile.notification.reinvite.error')}</p>,
        });
      });
  };

  const isCurrentUser = () => user.id === currentUser.id;

  const isDisabledUser = () => ['blocked', 'inactive'].includes(user.status);

  const showToogleStatusButton = () => ['confirmed', 'blocked', 'inactive'].includes(user.status);

  const showReInviteButton = () => ['invited', 'inviteError'].includes(user.status);

  const toogleUserStatus = () => {
    const { activate, deactivate } = ApiUser;
    const isDisabled = isDisabledUser();
    const toogle = isDisabled ? activate(user.id) : deactivate(user.id);
    toogle
      .then(() => {
        openNotification({
          type: 'success',
          message: (
            <p>
              {t(`profile.notification.toogle_user.${isDisabled ? 'activated' : 'deactivated'}`, {
                name: user.fullName || `${user.firstName} ${user.lastName}`,
              })}
            </p>
          ),
        });
      })
      .catch(() => {
        openNotification({
          type: 'error',
          message: <p>{t('profile.notification.toogle_user.error')}</p>,
        });
      })
      .finally(() => {
        fetchUsersList();
        navigation('/user-management');
      });
  };

  const userRoleRender = role => {
    const roles = {
      admin: 'Administrator',
      user: 'User',
    };

    return <Tag className={`profile__user-role--${role}`}>{roles[role]}</Tag>;
  };

  const userStatusRender = status => {
    const statuses = {
      invited: 'Invited',
      inviteError: 'Invited error',
      confirmed: 'Active',
      blocked: 'Blocked',
      inactive: 'Inactive',
    };

    return <Tag className={`profile__user-status--${status}`}>{statuses[status] || 'N/A'}</Tag>;
  };

  const onAvatarChange = avatarUrl => {
    setNewUserAvatar(avatarUrl);
  };

  const onRoleChange = id => {
    setHasChanges(true);
    setUserRole(id);
  };

  const editToogle = () => {
    form.resetFields();
    setIsFieldsInit(false);
    setHasChanges(false);
    setEdit(!isEdit);
  };

  const openPasswordModal = () => {
    setOpenPassword(true);
  };

  const closePasswordModal = () => {
    setOpenPassword(false);
  };

  const openUserDeleteModal = () => {
    setOpenDeleteModal(true);
  };

  const closeDeleteUserModal = () => {
    setOpenDeleteModal(false);
  };

  const close = () => {
    setEdit(false);
    setIsFieldsInit(false);
    setHasChanges(false);
    form.resetFields();
    dispatch(setIsOpenProfile(false));
    dispatch(setProfileUser());
  };

  const onFieldsChange = () => {
    if (!isFieldsInit) {
      setIsFieldsInit(true);
      return;
    }

    if (!hasChanges) {
      setHasChanges(true);
    }
  };

  if (isOpenProfile && user) {
    return (
      <ProfileView
        user={user}
        form={form}
        isEdit={isEdit}
        editToogle={editToogle}
        updateUser={updateUser}
        isOpenProfile={isOpenProfile}
        isOpenPassword={isOpenPassword}
        close={close}
        closePasswordModal={closePasswordModal}
        openPasswordModal={openPasswordModal}
        isDataLoaded={isDataLoaded}
        t={t}
        roleOptions={roleOptions}
        isCurrentUser={isCurrentUser}
        isDisabledUser={isDisabledUser}
        showToogleStatusButton={showToogleStatusButton}
        toogleUserStatus={toogleUserStatus}
        onRoleChange={onRoleChange}
        userStatusRender={userStatusRender}
        userRoleRender={userRoleRender}
        reInvite={reInvite}
        showReInviteButton={showReInviteButton}
        onAvatarChange={onAvatarChange}
        isAdmin={isAdmin}
        isOpenDeleteModal={isOpenDeleteModal}
        openUserDeleteModal={openUserDeleteModal}
        closeDeleteUserModal={closeDeleteUserModal}
        hasChanges={hasChanges}
        onFieldsChange={onFieldsChange}
      />
    );
  }

  return null;
};

export default Profile;
