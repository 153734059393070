import { useMemo, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const colors = [
  '#F44336',
  '#E91E63',
  '#9C27B0',
  '#673AB7',
  '#3F51B5',
  '#2196F3',
  '#03A9F4',
  '#00BCD4',
  '#009688',
  '#4CAF50',
  '#8BC34A',
  '#CDDC39',
  '#FFEB3B',
  '#FFC107',
  '#FF9800',
  '#FF5722',
  '#795548',
  '#9E9E9E',
  '#607D8B',
];

const Breakdowns = ({ total, categories = [] }) => {
  const { t } = useTranslation();
  const [boxWidth, setBoxWidth] = useState(0);
  const [select, setSelect] = useState();
  const navigate = useNavigate();

  const getWidthByPercentage = count => {
    const result = count / total;
    return result > 0.01 ? boxWidth * result : boxWidth * 0.01;
  };

  const toCategoryPage = id => {
    navigate(`/assets/category/${id}`);
  };

  const selectId = id => {
    setSelect(id);
  };

  const resetSelectId = () => {
    setSelect();
  };

  const lineItemClass = id => {
    let className = 'dashboard__breakdowns__line__item';

    if (select && select === id) {
      className += ' dashboard__breakdowns__line__item--selected';
    }

    if (select && select !== id) {
      className += ' dashboard__breakdowns__line__item--opacity';
    }

    return className;
  };

  const categoryItemClass = id => {
    let className = 'dashboard__breakdowns__categories__item';

    if (select && select !== id) {
      className += ' dashboard__breakdowns__categories__item--opacity';
    }

    return className;
  };

  const line = useMemo(() => {
    if (!boxWidth) {
      return null;
    }

    return (
      <div className="dashboard__breakdowns__line">
        {categories.map((category, index) => (
          <div
            key={index}
            className={lineItemClass(category.id)}
            style={{ backgroundColor: colors[index], width: getWidthByPercentage(category.assetCount) }}
            onClick={() => toCategoryPage(category.id)}
            onMouseEnter={() => selectId(category.id)}
            onMouseLeave={() => resetSelectId()}
            role="presentation"
          />
        ))}
      </div>
    );
  }, [categories, boxWidth, select]);

  const categoriesMarks = useMemo(
    () => (
      <ul className="dashboard__breakdowns__categories">
        {categories.map((category, index) => (
          <li
            key={index}
            className={categoryItemClass(category.id)}
            onClick={() => toCategoryPage(category.id)}
            onMouseEnter={() => selectId(category.id)}
            onMouseLeave={() => resetSelectId()}
            role="presentation"
            data-testid="categories-item"
          >
            <div className="dashboard__breakdowns__categories__mark" style={{ background: colors[index] }} />
            <span>{category.name}</span>
            <span className="dashboard__breakdowns__categories__count">{category.assetCount}</span>
          </li>
        ))}
      </ul>
    ),
    [categories, select]
  );

  useEffect(() => {
    const container = document.querySelector('.dashboard__breakdowns');
    setBoxWidth(container.offsetWidth - 100);
  }, []);

  return (
    <div className="dashboard__breakdowns">
      <h3>{t('dashboard.breakdowns.title')}</h3>
      {line}
      {categoriesMarks}
    </div>
  );
};

export default Breakdowns;
