import { configureStore } from '@reduxjs/toolkit';
import user from './slices/user';
import assets from './slices/assets';
import categories from './slices/categories';
import domains from './slices/domains';
import usersManagement from './slices/user_management';
import trails from './slices/trails';

const reducer = {
  user,
  assets,
  domains,
  categories,
  usersManagement,
  trails,
};

export default configureStore({ reducer });
